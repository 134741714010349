<template>
    <div>
        <div class="full-page">
            <div class="row" v-if="user">
                <div class="col-12 pb-5">
                    <h2 class="text-white">Welcome back, {{ user.name }}</h2>
                    <register-alert></register-alert>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-6 col-sm-12 pb-5 pb-lg-0" v-if="paidUser">
                    <current-month-rewards></current-month-rewards>
                </div>
                <div class="col-lg-6 col-sm-12" v-if="contract">
                    <stats-card title="Summary"
                                type="gradient-green"
                                sub-title="My Latest Agreement (Exclusive)"
                                icon="fas fa-file-contract"
                                v-if="contract.contract_type === $constants.contractType.exclusive"
                    >
                        <template slot="footer">
                            <dl class="row">
                                <dt class="col-sm-4">Payment type</dt>
                                <dd class="col-sm-8">{{ contract.payment_type_public_name }}</dd>

                                <dt class="col-sm-4">Amount of valid agreements</dt>
                                <dd class="col-sm-8">{{ user.amount_of_valid_agreements }}</dd>

                                <dt class="col-sm-4">Amount of licensed videos</dt>
                                <dd class="col-sm-8">{{ user.amount_of_licensed_videos }}</dd>
                            </dl>
                        </template>
                    </stats-card>
                    <stats-card title="Summary"
                                type="gradient-green"
                                sub-title="My Latest Agreement (Non-Exclusive)"
                                icon="fas fa-file-contract"
                                v-if="contract.contract_type === $constants.contractType.non_exclusive"
                    >
                        <template slot="footer">
                            <dl class="row">
                                <dt class="col-sm-4">Payment type</dt>
                                <dd class="col-sm-8">{{ contract.payment_type_public_name }}</dd>

                                <dt class="col-sm-4">Expiration date</dt>
                                <dd class="col-sm-8">{{ contract.end_date }}</dd>

                                <dt class="col-sm-4">Access to</dt>
                                <dd class="col-sm-8">{{ contract.permission_name }}</dd>
                            </dl>
                        </template>
                    </stats-card>
                </div>
            </div>

            <div class="row pb-5">
                <div class="col-lg-6 col-sm-12 pb-5 pb-lg-0" v-if="paidUser">
                    <card type="white" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-muted text-uppercase ls-1 mb-1">Overview</h6>
                                <h5 class="h3 text-default mb-0">My Rewards</h5>
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-sm btn-success" @click="$router.push('rewards')">
                                    Billing Details
                                </button>
                            </div>
                        </div>
                        <line-chart :height="350"
                                    :chart-data="rewards.chartData"
                                    :extra-options="rewards.extraOptions"
                        ></line-chart>
                    </card>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <card type="white" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-muted text-uppercase ls-1 mb-1">Overview</h6>
                                <h5 class="h3 text-default mb-0">My Videos</h5>
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-sm btn-success" @click="$router.push('library')">
                                    View Library
                                </button>
                            </div>
                        </div>
                        <line-chart :height="350"
                                    :chart-data="videos.chartData"
                                    :extra-options="videos.extraOptions"
                        ></line-chart>
                    </card>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import CurrentMonthRewards from '@/views/Rewards/CurrentMonthRewards.vue';
import RegisterAlert from '@/views/Alerts/Register.vue';

export default {
    components: {
        RegisterAlert,
        CurrentMonthRewards,
        LineChart,
    },
    data() {
        return {
            user: null,
            contract: null,
            rewards: {
                chartData: {
                    datasets: [
                        {
                            label: 'Paid',
                            data: [],
                            borderColor: '#fb6340',
                        },
                        {
                            label: 'Earned',
                            data: [],
                            borderColor: '#2dce89'
                        },
                    ],
                    labels: [],
                },
                extraOptions: chartConfigs.rewardChartOptions,
            },
            videos: {
                chartData: {
                    datasets: [
                        {
                            label: 'Posted by Bored Panda',
                            yAxisID: 'videos',
                            data: [],
                            borderColor: '#fb6340',
                        },
                        {
                            label: 'Video Views',
                            yAxisID: 'views',
                            data: [],
                            borderColor: '#2dce89',
                        },
                    ],
                    labels: [],
                },
                extraOptions: chartConfigs.videosChartOptions,
            },
        };
    },
    mounted() {
        this.$store.dispatch('getUser').then((user) => {
            this.contract = user.active_contract;
            this.user = user;
        });
        this.fetchDashboard();
    },
    computed: {
        paidUser() {
            return this.contract && this.contract.payment_type !== this.$constants.paymentType.free;
        },
    },
    methods: {
        fetchDashboard() {
            this.axios.get('./user/rewards/dashboard.json').then(x => x.data).then((dashboard) => {
                this.rewards.chartData = {
                    datasets: [
                        {
                            label: 'Paid',
                            data: dashboard.rewards.paid,
                            borderColor: '#fb6340',
                        },
                        {
                            label: 'Earned',
                            data: dashboard.rewards.earned,
                            borderColor: '#2dce89'
                        },
                    ],
                    labels: dashboard.months,
                };
                this.videos.chartData = {
                    datasets: [
                        {
                            label: 'Posted by Bored Panda',
                            data: dashboard.videos.posted,
                            borderColor: '#fb6340',
                        },
                        {
                            label: 'Video Views',
                            data: dashboard.videos.views,
                            borderColor: '#2dce89',
                        },
                    ],
                    labels: dashboard.months,
                };
            }).catch(error => {
                this.console.log(error);
            });
        },
    },
};
</script>
<style></style>

<template>
    <div>
        <b-modal id="artist-modal" scrollable size="sm" hide-footer>
            <div class="row">
                <div class="col-lg-12">
                    <spinner v-model="loading"></spinner>
                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger">
                    </b-alert>
                    <div class="form-group">
                        <label class="form-control-label">Deal id</label>
                        <base-input v-model="dealId" placeholder="Deal id from PD"></base-input>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="$bvModal.hide('artist-modal')" class="btn btn-light mt-3">Close
                    </button>
                    <button type="button" @click="createContract" class="btn btn-primary mt-3">Create Contract</button>
                </div>
            </div>
        </b-modal>

        <b-modal id="contract-modal" scrollable size="xl" hide-footer title="Contract configuration">
            <div class="row">
                <spinner v-model="loading"></spinner>
                <div class="col-lg-6">
                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                    <div class="form-group mb-2" v-if="dealData">
                        <a :href="'https://app.pipelinecrm.com/people/' + dealData.artist.artist_id" target="_blank">
                            {{ dealData.artist.name }}
                        </a>
                    </div>

                    <div class="form-group mb-2 disabled">
                        <label class="form-control-label">Agreement type</label>
                        <select class="form-control form-control-sm" v-model="artistData.contractType"
                                disabled="disabled"
                                :class="{'is-invalid': errors.contract_type.message}">
                            <option value="">Choose...</option>
                            <option v-for="(name, value) in formData.contractTypes" v-bind:key="value" :value="value">
                                {{ name }}
                            </option>
                        </select>
                        <div class="invalid-feedback text-danger" v-if="errors.contract_type.message"
                             v-text="errors.contract_type.message"></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Date</label>
                        <b-form-datepicker size="sm" locale="lt" v-model="artistData.date"
                                           :class="{'is-invalid': errors.date.message}"
                                           :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        ></b-form-datepicker>
                        <div class="invalid-feedback text-danger" v-if="errors.date.message"
                             v-text="errors.date.message"></div>
                    </div>

                    <base-input
                        v-model="artistData.contractDuration"
                        type="number"
                        :label="'Contract duration (months)'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.contract_duration.message"
                    ></base-input>

                    <div class="form-group mb-2">
                        <base-checkbox :class="{'is-invalid': errors.auto_renewal.message}"
                                       v-model="artistData.autoRenewal">
                            Auto contract renewal
                        </base-checkbox>
                    </div>

                    <base-input
                        v-model="artistData.name"
                        :label="'Full name'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :disabled="artistData.userId"
                        :error="errors.name.message"
                    ></base-input>

                    <base-input
                        v-model="artistData.email"
                        :label="'Email'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :disabled="artistData.userId"
                        :error="errors.email.message"
                    ></base-input>

                    <base-input
                        v-model="artistData.address"
                        :label="'Address'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :disabled="artistData.userId"
                        :error="errors.address.message"
                    ></base-input>

                    <div class="form-group mb-2">
                        <label class="form-control-label">BP channel</label>
                        <select class="form-control form-control-sm" v-model="artistData.bpChannel"
                                :class="{'is-invalid': errors.bp_channel.message}">
                            <option value="">Choose...</option>
                            <option v-for="channel in formData.channels" :key="channel.value" :value="channel.value">
                                {{ channel.name }}
                            </option>
                        </select>
                        <div class="invalid-feedback text-danger"
                             v-if="errors.bp_channel.message"
                             v-text="errors.bp_channel.message"
                        ></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Access given to:</label>
                        <base-radio v-for="(permission, id) in formData.permissions" v-bind:key="id"
                                    :class="{'is-invalid': errors.permission.message}"
                                    :name="id" v-model="artistData.permission">
                            {{ permission }}
                        </base-radio>
                        <div class="invalid-feedback text-danger" v-if="errors.permission.message"
                             v-text="errors.permission.message"></div>
                    </div>

                    <base-input
                        v-if="artistData.permission === $constants.permission.channel"
                        v-model="artistData.channelLink"
                        :label="'Channel link'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.channel_link.message"
                    ></base-input>

                    <div class="videos-list" v-if="artistData.permission === $constants.permission.video">
                        <base-input
                            v-for="videoLink in artistData.videoLinks"
                            v-bind:key="videoLink.key"
                            v-model="videoLink.link"
                            :label="'Video link (' + videoLink.key + ')'"
                            :input-classes="'form-control form-control-sm'"
                            :class="'form-group mb-2'"
                        ></base-input>

                        <div class="add-video-link text-right">
                            <a href="#" class="text-sm" @click.prevent="addVideoLink">+ Add another video</a>
                        </div>
                    </div>

                    <div class="denied-videos-list" v-if="artistData.permission === $constants.permission.channel">
                        <base-input
                            v-for="videoLink in artistData.deniedVideoLinks"
                            v-bind:key="videoLink.key"
                            v-model="videoLink.link"
                            :label="'Denied video link (' + videoLink.key + ')'"
                            :input-classes="'form-control form-control-sm'"
                            :class="'form-group mb-2'"
                            :error="errors.denied_video_links.message"
                        ></base-input>

                        <div class="add-video-link text-right">
                            <a href="#" class="text-sm" @click.prevent="addDeniedVideoLink">+ Add denied video</a>
                        </div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label" v-text="isExclusive ? 'Bonus Type' : 'Payment Type'"></label>
                        <select class="form-control form-control-sm" v-model="artistData.paymentType"
                                :class="{'is-invalid': errors.payment_type.message}">
                            <option value="">Choose...</option>
                            <option v-for="(name, value) in formData.availablePaymentTypes" v-bind:key="value" :value="value">
                                {{ name }}
                            </option>
                        </select>
                        <div class="invalid-feedback text-danger" v-if="errors.payment_type.message"
                             v-text="errors.payment_type.message"></div>
                    </div>

                    <template v-if="!isExclusive">
                        <base-input
                            v-if="artistData.paymentType !== $constants.paymentType.free"
                            v-model="artistData.amount"
                            type="number"
                            :label="amountConfig.label"
                            :input-classes="'form-control form-control-sm'"
                            :class="'form-group mb-2'"
                            :error="errors.amount.message"
                            :step="amountConfig.step"
                            :min="amountConfig.min"
                            :max="amountConfig.max"
                        ></base-input>

                        <base-input
                            v-if="artistData.paymentType !== $constants.paymentType.free"
                            v-model="artistData.monthlyFee"
                            type="number"
                            :label="'Monthly fee'"
                            :input-classes="'form-control form-control-sm'"
                            :class="'form-group mb-2'"
                            :error="errors.monthly_fee.message"
                        ></base-input>
                    </template>

                    <base-input
                        v-if="isExclusive || (artistData.paymentType !== $constants.paymentType.free &&
                              artistData.paymentType !== $constants.paymentType.oneTimeFee)"
                        v-model="artistData.fixedFee"
                        type="number"
                        :label="isExclusive ? 'One time fee per video/channel' : 'One time fee'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.fixed_fee.message"
                    ></base-input>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Permission to post in:</label>
                        <div v-for="platform in formData.platforms" v-bind:key="platform.id">
                            <base-checkbox v-model="platform.checked"
                                           :class="{'is-invalid': errors.granted_channels.message}">
                                {{ platform.name }}
                            </base-checkbox>
                            <base-checkbox v-for="channel in platform.channels" v-bind:key="channel.id"
                                           class="ml-5"
                                           v-model="channel.checked"
                                           :disabled="platform.checked"
                                           :class="{'is-invalid': errors.granted_channels.message}">
                                {{ channel.name }}
                            </base-checkbox>
                        </div>
                        <div class="invalid-feedback text-danger" v-if="errors.granted_channels.message"
                             v-text="errors.granted_channels.message"></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Credits</label>
                        <base-radio name="agreed" v-model="artistData.creditsAgreed"
                                    :class="{'is-invalid': errors.credits_agreed.message}">
                            Yes
                        </base-radio>
                        <base-radio name="not_required" v-model="artistData.creditsAgreed"
                                    :class="{'is-invalid': errors.credits_agreed.message}">
                            No
                        </base-radio>
                        <div class="invalid-feedback text-danger" v-if="errors.credits_agreed.message"
                             v-text="errors.credits_agreed.message"></div>
                    </div>

                    <div class="form-group mb-2" v-if="artistData.creditsAgreed === 'agreed'">
                        <textarea class="form-control form-control-sm" v-model="artistData.credits"
                                  :class="{'is-invalid': errors.credits.message}"
                        ></textarea>
                        <div class="invalid-feedback text-danger" v-if="errors.credits.message"
                             v-text="errors.credits.message"></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Custom contract required</label>
                        <base-radio :name="0" v-model="artistData.customContractRequired"
                                    :class="{'is-invalid': errors.contract_type.message}"
                                    @input="checkCustomContract">
                            No
                        </base-radio>
                        <base-radio :name="1" v-model="artistData.customContractRequired"
                                    @input="checkCustomContract">
                            Yes
                        </base-radio>
                    </div>

                    <b-form-file v-model="customContract.file"
                                 v-if="artistData.customContractRequired"
                                 :class="{'is-invalid': errors.custom_contract.message}"
                                 accept="application/pdf"
                                 placeholder="Choose a file or drop it here..."
                                 drop-placeholder="Drop file here..."
                                 @input="uploadCustomContract"
                    ></b-form-file>
                    <div class="invalid-feedback text-danger" v-if="errors.custom_contract.message"
                         v-text="errors.custom_contract.message"></div>

                    <div>
                        <input type="hidden" :class="{'is-invalid': errors.custom_contract_required.message
                                                                    || errors.contract_filename.message
                                                                    || errors.artist_id.message
                                                                    || errors.deal_id.message}">
                        <div class="invalid-feedback text-danger" v-if="errors.custom_contract_required.message"
                             v-text="errors.custom_contract_required.message"></div>
                        <div class="invalid-feedback text-danger" v-if="errors.contract_filename.message"
                             v-text="errors.contract_filename.message"></div>
                        <div class="invalid-feedback text-danger" v-if="errors.artist_id.message"
                             v-text="errors.artist_id.message"></div>
                        <div class="invalid-feedback text-danger" v-if="errors.deal_id.message"
                             v-text="errors.deal_id.message"></div>
                    </div>
                </div>

                <div class="col-lg-6 text-center">
                    <pdf-viewer :src="artistData.contractUrl" @pdf-loaded="pdfLoaded"></pdf-viewer>
                    <button class="btn btn-primary mt-3" @click="generateTemporaryContract">Generate Contract</button>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="$bvModal.hide('contract-modal')" class="btn btn-light mt-3">
                        Close
                    </button>
                    <button v-if="user" type="button" @click="submitContract" class="btn btn-primary mt-3">Create
                    </button>
                    <button v-else type="button" @click="sendInvite" class="btn btn-primary mt-3">Send invite</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

import {forEach, map} from 'lodash';
import {BAlert, BFormDatepicker, BFormFile} from 'bootstrap-vue';
import PdfViewer from '@/components/PdfViewer.vue';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';
import UploadFile from '@/mixins/UploadFile';
import constants from "@/constants";

export default {
    name: 'new-artist',
    components: {BFormDatepicker, PdfViewer, Spinner, BAlert, BFormFile},
    mixins: [ProcessError, UploadFile],
    props: {
        user: {
            type: Object,
            description: 'User required when adding new contract for existing artist',
        },
    },
    data() {
        return {
            dealId: null,
            isExclusive: 0,
            dealData: null,
            userData: null,
            errors: {
                form: {show: false, message: ''},
                contract_type: {message: ''},
                payment_type: {message: ''},
                monthly_fee: {message: ''},
                fixed_fee: {message: ''},
                date: {message: ''},
                name: {message: ''},
                email: {message: ''},
                address: {message: ''},
                permission: {message: ''},
                bp_channel: {message: ''},
                channel_link: {message: ''},
                denied_video_links: {message: ''},
                contract_duration: {message: ''},
                amount: {message: ''},
                granted_channels: {message: ''},
                credits_agreed: {message: ''},
                credits: {message: ''},
                custom_contract_required: {message: ''},
                custom_contract: {message: ''},
                contract_filename: {message: ''},
                artist_id: {message: ''},
                deal_id: {message: ''},
                auto_renewal: {message: ''},
            },
            customContract: {
                file: null,
                filename: null,
                url: null,
            },
            loading: false,
            artistData: {
                artistId: null,
                dealId: null,
                userId: null,
                contractUrl: '',
                contractFilename: '',
                contractType: '',
                paymentType: '',
                monthlyFee: 0,
                fixedFee: 0,
                date: null,
                name: '',
                email: null,
                address: '',
                permission: null,
                channelLink: '',
                videoLinks: [
                    {key: 1, link: ''},
                ],
                deniedVideoLinks: [],
                amount: null,
                grantedChannels: {},
                contractDuration: 12,
                autoRenewal: true,
                customContractRequired: null,
                customContract: null,
                creditsAgreed: 'not_required',
                credits: 'Watermark',
                bpChannel: null,
            },
            formData: {
                contractTypes: {},
                availablePaymentTypes: {},
                allPaymentTypes: {},
                exclusivePaymentTypes: {},
                permissions: {},
                platforms: {},
                channels: [
                    {
                        value: constants.channels.craftyPanda,
                        name: constants.channelName[constants.channels.craftyPanda]
                    },
                    {
                        value: constants.channels.ladyPanda,
                        name: constants.channelName[constants.channels.ladyPanda]
                    },
                ],
            },
        }
    },
    mounted() {
        this.getFormData();
        this.userData = this.user;
    },
    computed: {
        amountConfig() {
            let config = {step: 1, min: 1, max: 100, label: ''};
            if (this.artistData.paymentType === this.$constants.paymentType.oneTimeFee) {
                config.label = 'Amount for (one video) / (whole channel) $';
                config.max = 100000;
            } else if (this.artistData.paymentType === this.$constants.paymentType.revenueShare) {
                config.label = 'Revenue share %';
                config.min = 10;
                config.max = 60;
            } else if (this.artistData.paymentType === this.$constants.paymentType.internalRPM) {
                config.label = 'Internal RPM';
                config.step = 0.001;
                config.min = 0;
                config.max = 0.099;
            } else if (this.artistData.paymentType === this.$constants.paymentType.stairs) {
                config.label = '1M views reward $';
            }

            return config;
        },
    },
    methods: {
        getFormData() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./admin/artists/form-data.json').then(x => x.data).then(data => {
                this.formData.permissions = data.permissions;
                this.formData.contractTypes = data.contract_types;
                this.formData.allPaymentTypes = data.payment_types;
                this.formData.exclusivePaymentTypes = data.exclusive_payment_types;
                this.formData.platforms = map(data.platforms, (platform) => {
                    const channels = [];

                    forEach(platform.channels, (channel) => {
                        channels.push({id: channel.id, name: channel.title, checked: false});
                    });

                    return {id: platform.id, name: platform.title, channels: channels, checked: false}
                });
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
        checkCustomContract() {
            if (this.customContract.filename && this.artistData.customContractRequired === 1) {
                if (this.artistData.contractUrl !== this.customContract.url) {
                    this.customContract.file = null;
                    this.artistData.customContract = null;
                    this.artistData.contractUrl = null;
                } else {
                    this.artistData.customContract = this.customContract.filename;
                    this.artistData.contractUrl = this.customContract.url;
                }
            }
        },
        uploadCustomContract() {
            if (this.customContract.file) {
                this.customContract.filename = null;
                this.customContract.url = null;
                this.loading = true;
                this.uploadFile(this.customContract.file, this.uploadCallback);
            }
        },
        uploadCallback(data) {
            this.customContract.filename = data.filename;
            this.customContract.url = data.url;
            this.artistData.customContract = data.filename;
            this.artistData.contractUrl = data.url;
            this.loading = false;
        },
        createContract() {
            if (this.dealId) {
                this.resetErrors();
                this.loading = true;
                this.axios.get('./admin/artists/get.json?deal_id=' + this.dealId).then(x => x.data)
                    .then(data => {
                        this.dealData = data;
                        this.isExclusive = this.dealData.is_exclusive;
                        if (!this.userData && data.artist && data.artist.user) {
                            this.userData = data.artist.user;
                        }
                        if (!this.isExclusive && this.hasActiveContract()) {
                            this.errors.form.show = true;
                            this.errors.form.message = 'Please terminate current contracts before creating a new one!';
                            this.loading = false;
                            return false;
                        }
                        this.fillArtistData();
                        this.setAvailablePaymentTypes();
                        this.generateTemporaryContract();
                        this.$bvModal.hide('artist-modal');
                        this.$bvModal.show('contract-modal');
                    }).catch(error => {
                    this.processError(error);
                });
            }
        },
        hasActiveContract() {
            return this.userData && this.userData.contracts.filter((contract) => {
                return !this.isExclusive
                    && contract.contract_type === this.$constants.contractType.non_exclusive
                    && contract.status !== this.$constants.contractStatus.terminated
                    && [
                        this.$constants.paymentType.oneTimeFee,
                        this.$constants.paymentType.free
                    ].includes(contract.payment_type) === false
            }).length > 0;
        },
        fillArtistData() {
            this.artistData.date = new Date();
            this.artistData.artistId = this.dealData.artist.id;
            this.artistData.dealId = this.dealData.id;
            this.artistData.contractType = this.isExclusive;
            this.artistData.bpChannel = this.dealData.bp_channel ? this.dealData.bp_channel.id : null;
            this.artistData.email = this.dealData.artist.email;
            this.artistData.paymentType = !this.isExclusive ? this.dealData.payment_type : null;
            this.artistData.amount = this.dealData.amount;
            this.artistData.permission = this.dealData.permission;
            // this.artistData.creditsAgreed = this.dealData.credits || this.artistData.credits ? 'agreed' : null;
            this.artistData.credits = this.dealData.credits ? this.dealData.credits : this.artistData.credits;
            forEach(this.dealData.deal_permissions, (permission) => {
                if (permission.type === this.$constants.deal_permission_type.platform) {
                    const index = this.formData.platforms.findIndex(p => p.id === permission.platform_id);
                    if (index > -1) {
                        this.formData.platforms[index].checked = true;
                    }
                } else if (permission.type === this.$constants.deal_permission_type.channel) {
                    this.formData.platforms = this.formData.platforms.map(platform => {
                        const index = platform.channels.findIndex(c => c.id === permission.channel_id);
                        if (index > -1) {
                            platform.channels[index].checked = true;
                        }
                        return platform;
                    });
                }
            });
            if (this.userData) {
                this.artistData.name = this.userData.name;
                this.artistData.email = this.userData.email;
                this.artistData.address = this.userData.address;
                this.artistData.userId = this.userData.id;
            }
        },
        sendInvite() {
            this.$confirm.require(
                {
                    message: `Are you sure want to invite this artist now?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.processContractPost();
                    }
                }
            );
        },
        submitContract() {
            this.$confirm.require(
                {
                    message: `Are you sure want to create a new contract?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.processContractPost();
                    }
                }
            );
        },
        processContractPost() {
            this.resetErrors();
            this.loading = true;
            this.artistData.grantedChannels = this.formData.platforms;
            this.axios.post('./admin/artists/create.json', this.artistData).then(x => x.data)
                .then(() => {
                    this.$bvModal.hide('contract-modal');
                    this.loading = false;
                    this.$emit('after-submit');
                })
                .catch(error => {
                    document.getElementsByClassName('modal-body')[0].scrollTop = 0;
                    this.processError(error);
                });
        },
        generateTemporaryContract() {
            if (!this.artistData.customContractRequired) {
                this.loading = true;
                this.artistData.grantedChannels = this.formData.platforms;
                this.axios.post(
                    './admin/contracts/generate-temporary.json',
                    this.artistData
                ).then(x => x.data).then((data) => {
                    this.artistData.contractUrl = data.url;
                });
            }
        },
        pdfLoaded() {
            this.loading = false;
        },
        addVideoLink() {
            this.artistData.videoLinks.push({key: (this.artistData.videoLinks.length + 1), link: ''});
        },
        addDeniedVideoLink() {
            this.artistData.deniedVideoLinks.push({key: (this.artistData.deniedVideoLinks.length + 1), link: ''});
        },
        setAvailablePaymentTypes() {
            this.formData.availablePaymentTypes = this.isExclusive
                ? this.formData.exclusivePaymentTypes
                : this.formData.allPaymentTypes.filter((name, type) =>
                    (this.formData.exclusivePaymentTypes[type] === undefined));
        }
    }
};
</script>

<style lang="scss" scoped>
</style>

<template>
    <div class="row">
        <div class="col-lg-4 col-sm-12 mb-3">
            <input type="text" class="form-control form-control-sm" @change="search" v-model="filters.keyword"
                   placeholder="Search...">
        </div>
        <div class="col-lg-1 col-sm-12 mb-3">
            <button v-if="filters.keyword" @click="clearFilters" class="btn btn-sm btn-light" type="button">
                Clear
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'list-table-filters',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    computed: {
        filters: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    methods: {
        search() {
            this.$emit('search');
        },
        clearFilters: function () {
            this.$emit('clearFilters');
            this.search();
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
    <b-card>
        <template #header>
            <slot name="header"></slot>
        </template>

        <list-table-filters v-model="filters"
                            v-if="showFilters"
                            @clearFilters="clearFilters"
                            @search="searchEntries"
        ></list-table-filters>

        <spinner v-model="loading"></spinner>

        <div v-if="isEmpty">
            <h4>List is empty.</h4>
        </div>

        <div class="table-responsive">
            <b-table id="list-table" class="table" thead-class="thead-light"
                     v-if="!isEmpty"
                     sort-icon-right
                     :current-page="page"
                     :items="entries"
                     :fields="fields"
                     :sort-by.sync="by"
                     :sort-desc.sync="desc"
                     :sort-direction="'desc'"
                     :no-local-sorting="true"
                     @sort-changed="sortEntries"
            >

                <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                    <slot :name="slotName" v-bind="scope"/>
                </template>

            </b-table>
        </div>

        <template #footer>
            <b-pagination
                v-if="!isEmpty"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="list-table"
                :class="'justify-content-end'"
                @change="fetchEntries"
            ></b-pagination>
        </template>
    </b-card>
</template>
<script>

import {BCard, BPagination, BTable} from 'bootstrap-vue';
import ListTableFilters from '@/components/ListTableFilters.vue';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    name: 'list-table',
    components: {BCard, BTable, BPagination, ListTableFilters, Spinner},
    mixins: [ProcessError],
    props: {
        endpoint: {
            type: String,
        },
        requestParams: {
            type: String,
            default: '',
        },
        fields: {
            type: Array,
        },
        sortKeys: {
            type: Object,
            default: null
        },
        sortBy: {
            type: String,
            default: 'id',
        },
        sortDesc: {
            type: Boolean,
            default: true,
        },
        showFilters: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            entries: [],
            filters: {
                keyword: '',
            },
            page: 1,
            total: 0,
            perPage: 20,
            loading: false,
            by: this.sortBy,
            desc: this.sortDesc,
            error: {show: false, message: ''},
        };
    },
    computed: {
        isEmpty() {
            return this.entries.length === 0
        },
    },
    mounted() {
        this.fetchEntries(1);
    },
    methods: {
        mapSortBy(sortBy) {
            const key = sortBy || this.by;

            return this.sortKeys && key in this.sortKeys ? this.sortKeys[key] : key;
        },
        sortEntries(sorting) {
            this.page = 1;
            this.fetchEntries(this.page, sorting.sortBy, sorting.sortDesc);
        },
        searchEntries() {
            this.page = 1;
            this.fetchEntries(this.page);
        },
        fetchEntries(page, sortBy, sortDesc) {
            this.loading = true;
            this.resetErrors();
            sortDesc = typeof sortDesc === 'undefined' ? this.desc : sortDesc;
            this.axios.get(
                this.endpoint
                + '?page=' + page
                + '&perPage=' + this.perPage
                + '&sortBy=' + this.mapSortBy(sortBy)
                + '&sortType=' + (sortDesc ? 'desc' : 'asc')
                + '&search=' + this.filters.keyword
                + this.requestParams
            ).then(x => x.data).then((data) => {
                this.entries = data.data;
                this.total = data.total;
                this.perPage = data.per_page;
                this.loading = false;
            }).catch((error) => {
                this.processError(error);
            });
        },
        clearFilters() {
            this.filters.keyword = '';
        },
    }
};
</script>

<style lang="scss" scoped></style>

export default {
    data() {
        return {
            uploadPercentage: 0,
        };
    },
    methods: {
        uploadFile(file, callback) {
            this.axios.post('./upload/form-data.json', {
                filename: file.name,
                content_type: file.type
            }).then(x => x.data).then((data) => {
                this.axios.post(data.bucket, this.prepareFormData(data, file), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: function (progressEvent) {
                        this.uploadPercentage = parseInt(
                            Math.round((progressEvent.loaded / progressEvent.total) * 100)
                        );
                    }.bind(this),
                    transformRequest: (data, headers) => {
                        delete headers.common['Authorization'];
                        return data;
                    },
                }).then((s3Data) => {
                    if (s3Data.status !== 204) {
                        this.errors.form.message = 'Error uploading file!';
                        this.errors.form.show = true;
                    } else {
                        if (typeof callback === 'function') {
                            callback(data);
                        }
                    }
                }).catch(error => {
                    this.processError(error);
                });
            }).catch(error => {
                this.processError(error);
            });
        },
        prepareFormData(data, file) {
            let formData = new FormData();
            for (let key in data.multipartParams) {
                formData.append(key, data.multipartParams[key]);
            }
            formData.append('file', file);

            return formData;
        },
    },
}
<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <list-table :endpoint="endpoint" :fields="fields" :sortDesc="true" :sortKeys="sortKeys">
                <template #cell(artist_id)="data">
                    <a v-if="data.item.user.artist" target="_blank"
                       :href="'https://app.pipelinecrm.com/people/' + data.item.user.artist.artist_id">
                        {{ data.item.user.artist.artist_id }}
                    </a>
                </template>

                <template #cell(actions)="data">
                    <a :href="data.item.contract_url" class="ml-2" title="Show contract" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </template>
            </list-table>
        </div>
    </div>
</template>
<script>

import ListTable from '@/components/ListTable.vue';

export default {
    name: 'contracts',
    components: {ListTable},
    data() {
        return {
            endpoint: './admin/contracts/list.json',
            fields: [
                {key: 'id', label: '#', sortable: true},
                {key: 'user.name', label: 'Name', sortable: false},
                {key: 'user.email', label: 'Email', sortable: false},
                {key: 'artist_id', label: 'Artist id', sortable: false},
                {key: 'contract_type_name', label: 'Type', sortable: true},
                {key: 'payment_type_name', label: 'Payment type', sortable: true},
                {key: 'permission_name', label: 'Permission', sortable: true},
                {key: 'start_date', label: 'Start date', sortable: true},
                {key: 'end_date', label: 'Expiration date', sortable: true},
                {key: 'status_name', label: 'Status', sortable: true},
                {key: 'actions', label: '', sortable: false},
            ],
            sortKeys: {
                'id': 'id',
                'email': 'email',
                'contract_type_name': 'contract_type',
                'payment_type_name': 'payment_type',
                'permission_name': 'permission',
                'start_date': 'start_date',
                'end_date': 'end_date',
                'status_name': 'status',
            },
        }
    },
};
</script>

<style lang="scss" scoped></style>

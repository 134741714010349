<template>
    <div>
        <b-modal id="manage-contracts" scrollable size="xl" hide-footer>
            <template #modal-title>
                <h5 class="modal-title d-inline align-middle">Manage contracts</h5>
                <button v-if="user && user.is_active" @click="addNew" class="btn btn-sm btn-primary ml-3 align-middle">
                    Add new
                </button>
                <new-artist :user="user" @after-submit="addedContract"></new-artist>
            </template>
            <div class="row">
                <spinner v-model="loading"></spinner>
                <div class="col-lg-12" v-if="user">
                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                    <div class="row mb-3" v-for="contract in user.contracts" v-bind:key="contract.id">
                        <div class="col-lg-6 col-sm-12">
                            <pdf-viewer :src="contract.contract_url"></pdf-viewer>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <dl class="row">
                                <dt class="col-sm-6">Contract start date</dt>
                                <dd class="col-sm-6">{{ contract.start_date }}</dd>

                                <dt class="col-sm-6">Contract expiration date</dt>
                                <dd class="col-sm-6">{{ contract.end_date }}</dd>

                                <dt class="col-sm-6">Contract type</dt>
                                <dd class="col-sm-6">{{ contract.contract_type_name }}</dd>

                                <dt class="col-sm-6">Payment type</dt>
                                <dd class="col-sm-6">{{ contract.payment_type_name }}</dd>

                                <dt class="col-sm-6" v-if="contract.payment_type !== $constants.paymentType.free">Amount</dt>
                                <dd class="col-sm-6" v-if="contract.payment_type !== $constants.paymentType.free">
                                    {{ parseFloat(contract.amount) }}
                                </dd>

                                <dt class="col-sm-6">Granted channels</dt>
                                <dd class="col-sm-6">{{ contract.granted_channels.join(', ') }}</dd>

                                <dt class="col-sm-6">Permission</dt>
                                <dd class="col-sm-6">{{ contract.permission_name }}</dd>

                                <dt class="col-sm-6" v-if="contract.permission === $constants.permission.channel">
                                    Channel link
                                </dt>
                                <dd class="col-sm-6" v-if="contract.permission === $constants.permission.channel">
                                    {{ contract.channel_link }}
                                </dd>
                                <dd class="col-sm-6"
                                    v-if="contract.permission === $constants.permission.channel
                                          && contract.denied_video_links.length > 0">
                                    {{ contract.denied_video_links.join("\n") }}
                                </dd>

                                <dt class="col-sm-6" v-if="contract.permission === $constants.permission.video">
                                    Video links
                                </dt>
                                <dd class="col-sm-6" v-if="contract.permission === $constants.permission.video">
                                    {{ contract.video_links.join("\n") }}
                                </dd>

                                <template v-if="contract.contract_type === $constants.contractType.exclusive">
                                    <dt class="col-sm-6">Videos</dt>
                                    <dd class="col-sm-6">
                                        <a href="#" title="Manage contract videos"
                                           @click.prevent="manageContractVideos(contract)">
                                            <i class="fas fa-external-link-alt"></i>
                                        </a>
                                    </dd>
                                </template>

                                <dt class="col-sm-6">Custom contract</dt>
                                <dd class="col-sm-6">{{ contract.is_custom ? 'Yes' : 'No' }}</dd>

                                <dt class="col-sm-6">Status</dt>
                                <dd class="col-sm-6">{{ contract.status_name }}</dd>
                            </dl>
                            <div class="row" v-if="contract.status !== $constants.contractStatus.terminated">
                                <div class="col-lg-12">
                                    <button @click="terminate(contract)" class="btn btn-danger">Terminate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <contract-videos ref="contractVideos"></contract-videos>
    </div>
</template>

<script>

import PdfViewer from '@/components/PdfViewer.vue';
import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';
import NewArtist from '@/views/Artists/NewArtist.vue';
import ContractVideos from "@/views/Artists/ContractVideos.vue";

export default {
    name: 'manage-contracts',
    components: {ContractVideos, NewArtist, Spinner, BAlert, PdfViewer},
    mixins: [ProcessError],
    props: {
        user: {
            type: Object,
            description: 'Artist whose contracts to edit',
        },
    },
    data() {
        return {
            errors: {
                form: {show: false, message: ''},
            },
            loading: false,
        }
    },
    computed: {
        hasActiveContracts() {
            return false;
            // return this.user.contracts.filter((contract) => {
            //     return contract.status !== this.$constants.contractStatus.terminated &&
            //         [
            //             this.$constants.paymentType.oneTimeFee,
            //             this.$constants.paymentType.free
            //         ].includes(contract.payment_type) === false;
            // }).length > 0;
        }
    },
    methods: {
        terminate(contract) {
            this.$confirm.require(
                {
                    message: `Are you sure want to terminate this contract?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.axios.post('./admin/contracts/terminate.json', {
                            contract_id: contract.id,
                        }).then(() => {
                            contract.status = this.$constants.contractStatus.terminated;
                            contract.status_name = 'Terminated';
                            this.loading = false;
                        }).catch(error => {
                            this.processError(error);
                        });
                    }
                }
            );
        },
        addNew() {
            if (this.hasActiveContracts) {
                this.errors.form.message = 'Please terminate current contracts before creating a new one!'
                this.errors.form.show = true;
            } else {
                this.errors.form.show = false
                this.$bvModal.show('artist-modal');
            }
        },
        addedContract() {
            this.$bvModal.hide('manage-contracts');
            this.$emit('after-submit');
        },
        manageContractVideos(contract) {
            this.$refs.contractVideos.open(contract);
        },
    }
};
</script>

<style lang="scss" scoped>
</style>

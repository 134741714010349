<template>
    <b-toast id="registerToast" v-if="showToast" toaster="b-toaster-top-center" variant="success"
             title="Congratulations on successful signing in!" visible no-auto-hide no-close-button>
        <p>
            Please make sure that all the contact details are correct and up to date
            <a href="/my-details" target="_blank" class="alert-link text-success">here.</a>
        </p>
        <p v-if="contract && contract.payment_type !== $constants.paymentType.free">
            Please fill in your billing details
            <a href="/my-details" target="_blank" class="alert-link text-success">here.</a>
        </p>
        <div class="text-right">
            <button class="btn btn-sm btn-primary" @click="hideToast">Close</button>
        </div>
    </b-toast>
</template>
<script>
import {BToast} from 'bootstrap-vue';

export default {
    name: 'register-alert',
    components: {BToast},
    data() {
        return {
            contract: null,
        }
    },
    async mounted() {
        const user = await this.$store.dispatch('getUser');
        this.contract = user.active_contract;
    },
    computed: {
        showToast() {
            return this.$store.getters.registerAlert;
        }
    },
    methods: {
        hideToast() {
            this.$bvToast.hide('registerToast');
            this.$store.dispatch('hideRegisterAlert');
        }
    }
};
</script>
<style lang="scss" scoped>
</style>

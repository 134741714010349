<template>
    <div :class="'pdf-viewer position-' + position + ' size-' + size" v-if="src">
        <pdf ref="pdf"
             :src="src"
             :page="currentPage"
             @num-pages="pageCount = $event"
             @page-loaded="currentPage = $event"
             @loadstart="pdfLoadStarted"
             @loaded="pdfLoaded"
        ></pdf>

        <div class="controls text-center mt-1 mb-1" v-if="showControls">
            <a href="#" @click.prevent="previousPage">«</a>
            <span class="pages mr-2 ml-2">{{ currentPage }} / {{ pageCount }}</span>
            <a href="#" @click.prevent="nextPage">»</a>
            <a :href="src" class="open-external" title="Show contract" target="_blank">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </div>
    </div>
</template>
<script>
import pdf from 'vue-pdf';

export default {
    name: 'pdf-viewer',
    components: {pdf},
    props: {
        src: String,
        position: {
            type: String,
            default: 'center'
        },
        size: {
            type: String,
            default: 'md'
        },
    },
    data() {
        return {
            currentPage: 1,
            pageCount: 1,
            showControls: false,
        };
    },
    methods: {
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.pageCount) {
                this.currentPage++;
            }
        },
        pdfLoadStarted() {
            this.showControls = false;
        },
        pdfLoaded() {
            this.showControls = true;
            this.$emit('pdf-loaded');
        }
    }
};
</script>
<style lang="scss" scoped>
.pdf-viewer {
    border: 1px solid #ccc;
    max-width: 400px;
    background: #ffffff;
    overflow: hidden;

    &.size-lg {
        max-width: 100%;
    }

    &.position-center {
        margin: 0 auto;
    }

    .controls {
        position: relative;

        .pages {
            font-size: 0.8em;
        }

        .open-external {
            position: absolute;
            right: 5px;
            bottom: 0;
            font-size: 14px;
        }
    }
}
</style>

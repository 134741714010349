<template>
    <div class="container">
        <card v-if="paidUser">
            <div class="row">
                <div class="col-lg-12 text-center pb-3">
                    <h1 class="heading-title text-default">Frequently Asked Questions</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-dark faq">
                    <ul class="px-3">
                        <li>
                            <h3>Ownership of your video</h3>
                            We never obtain ownership of the videos and the Creator remains the legal and beneficial owner of, owns all the rights and interests in, and holds any and all intellectual property rights to the Videos. You as a Creator are just granting us the permission to license your video on agreed terms.
                        </li>
                        <li>
                            <h3>Validity of the agreement (Auto renewal)</h3>
                            Usually the agreement is signed and valid for 12 months from the day of its signing unless it's indicated otherwise in your Special Terms. The Term shall be automatically extended for successive periods of the same amount of months at the time, unless one Party has provided the other Party with a notice of termination not less than 30 days prior to the end of the Term.
                        </li>
                        <li>
                            <h3>Is there any guarantee that my video will be posted?</h3>
                            In most cases, the video is posted. Sometimes it might take a while. And in the rare cases when trending topics are completely changed, we might decide not to post the video, but to keep it in our database for the future.
                        </li>
                        <li>
                            <h3>When will I get paid?</h3>
                            All payments shall be paid to the Creator's banking account by the 20th day of the next calendar month, subject to the Minimum amount clause and Eligible period.
                        </li>
                        <li>
                            <h3>Eligible period</h3>
                            Period of days from posting / reposting of the Video to each Distribution channel where the Video is monetized.
                        </li>
                        <li>
                            <h3>Minimum amount</h3>
                            If the calculated remuneration amount is less than 50 US dollars in any given month, Pandos reserves the right to carry the accumulated amount over to the next month until it exceeds 50 US dollars. If the remuneration amount never exceeds 50 US dollars, then no payment by Pandos to the Creator will be due.
                        </li>
                        <li>
                            <h3>Why haven't I been paid?</h3>
                            If you see the payment status 'paid', but the funds didn't appear in your PayPal or bank account, firstly, please check the payment details you provided to us. If they are correct, then please write to us on <a href="mailto:partnership@boredpanda.com">partnership@boredpanda.com</a> shortly describing the problem.
                        </li>
                        <li>
                            <h3>Deleting video</h3>
                            If you'd like to change permission of your video, please <a href="mailto:partnership@boredpanda.com">contact us</a>.
                        </li>
                        <li>
                            <h3>Deleting account</h3>
                            If you'd like to terminate your account,  please <a href="mailto:partnership@boredpanda.com">contact us</a>. Please note that termination of one's account may only be performed in liaison with agreement termination.
                        </li>
                    </ul>
                    Have more questions? <a href="mailto:partnership@boredpanda.com">Submit a request</a>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            user: null,
        };
    },
    computed: {
        paidUser() {
            return this.user &&
                this.user.active_contract &&
                this.user.active_contract.payment_type !== this.$constants.paymentType.free;
        },
    },
    mounted() {
        this.$store.dispatch('getUser').then(user => {
            this.user = user;
        })
    },
}


</script>
<style lang="scss" scoped>
.faq {
    p, li {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: justify;
    }

    ul {
        padding-inline-start: 16px;

        li {
            padding-bottom: 30px;
        }
        &.romanian {
            list-style-type: lower-roman;
        }
    }
    .terms-footer {
        font-size: 12px;
    }
}
</style>

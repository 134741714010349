export default {
    methods: {
        downloadFile(data, name) {
            const fileURL = window.URL.createObjectURL(new Blob([data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
    },
}
<template>
    <div class="spinner-wrapper" v-if="spinning">
        <b-spinner class="spinner" variant="success" type="grow" label="Spinning"></b-spinner>
    </div>
</template>

<script>
import {BSpinner} from 'bootstrap-vue';

export default {
    components: {BSpinner},
    name: 'spinner',
    props: {
        value: {
            type: Boolean,
            description: "Determine if spinning"
        },
    },
    data() {
        return {
            focused: false
        };
    },
    computed: {
        spinning: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.spinner-wrapper {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-flex;

    .spinner {
        margin: auto;
        align-self: center;
    }
}
</style>

<template>
    <card>
        <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
        <b-alert v-model="success" variant="success">Successfully updated!</b-alert>
        <spinner v-model="loading"></spinner>
        <div class="form-row">
            <div class="form-group col-xl-6 col-lg-12 mb-2">
                <label class="form-control-label">Preferred billing method</label>
                <select class="form-control" v-model="billing.type"
                        :class="{'is-invalid': errors.type.message}">
                    <option value="">Please select</option>
                    <option :value="1">PayPal</option>
                    <option :value="2">I don't have PayPal</option>
                </select>
                <div class="invalid-feedback text-danger" v-if="errors.type.message"
                     v-text="errors.type.message"></div>
            </div>
        </div>
        <div class="form-row"
             v-if="parseInt(billing.type) === $constants.billingMethod.paypal">
            <base-input
                v-model="billing.paypalEmail"
                type="email"
                :label="'PayPal email'"
                :class="'form-group col-xl-6 col-lg-12 mb-2'"
                :error="errors.paypal_email.message"
            ></base-input>
        </div>
        <div v-if="parseInt(billing.type) === $constants.billingMethod.bank">
            <div class="form-row">
                <div class="form-group col-md-12 mb-2">
                    <label class="form-control-label">Your Legal Status</label>
                    <base-radio :name="1" v-model="billing.legalStatus"
                                :class="{'is-invalid': errors.legal_status.message}">
                        Individual
                    </base-radio>
                    <base-radio :name="2" v-model="billing.legalStatus"
                                :class="{'is-invalid': errors.legal_status.message}">
                        Company
                    </base-radio>
                    <div class="invalid-feedback text-danger" v-if="errors.legal_status.message"
                         v-text="errors.legal_status.message"></div>
                </div>
            </div>
            <div class="form-row">
                <base-input
                    v-model="billing.name"
                    :label="'Beneficiary\'s name'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.name.message"
                ></base-input>
                <base-input
                    v-model="billing.account"
                    :label="'Beneficiary\'s account'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.account.message"
                ></base-input>
                <base-input
                    v-model="billing.address"
                    :label="'Beneficiary\'s address'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.address.message"
                ></base-input>
                <base-input
                    v-model="billing.city"
                    :label="'Beneficiary\'s city'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.city.message"
                ></base-input>
                <base-input
                    v-model="billing.postcode"
                    :label="'Beneficiary\'s postcode'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.postcode.message"
                ></base-input>
                <base-input
                    v-model="billing.bankName"
                    :label="'Beneficiary\'s bank name'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.bank_name.message"
                ></base-input>
                <base-input
                    v-model="billing.swiftCode"
                    :label="'Beneficiary SWIFT'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.swift_code.message"
                ></base-input>
                <base-input
                    v-model="billing.additionalInfo"
                    :label="'Additional info'"
                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                    :error="errors.additional_info.message"
                ></base-input>
            </div>
        </div>
        <div class="form-row">
            <div class="col mt-3">
                <button @click="updateDetails" class="btn btn-primary">Update</button>
            </div>
        </div>
    </card>
</template>
<script>

import {BAlert, VBTooltip} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'billing',
    components: {Spinner, BAlert},
    directives: {'b-tooltip': VBTooltip},
    mixins: [ProcessError],
    data() {
        return {
            billing: {
                legalStatus: '',
                type: '',
                paypalEmail: '',
                name: '',
                account: '',
                address: '',
                city: '',
                postcode: '',
                bankName: '',
                swiftCode: '',
                additionalInfo: '',
            },
            success: false,
            loading: false,
            errors: {
                form: {show: false, message: ''},
                type: {message: ''},
                legal_status: {message: ''},
                paypal_email: {message: ''},
                name: {message: ''},
                account: {message: ''},
                address: {message: ''},
                city: {message: ''},
                postcode: {message: ''},
                bank_name: {message: ''},
                swift_code: {message: ''},
                additional_info: {message: ''},
            },
        };
    },
    mounted() {
        this.$store.dispatch('getUser').then((user) => {
            this.initUser(user);
        });
    },
    methods: {
        initUser(user) {
            if (user.billing_info) {
                this.billing.type = user.billing_info.type;
                this.billing.legalStatus = user.billing_info.legal_status ? user.billing_info.legal_status : null;
                this.billing.paypalEmail = user.billing_info.paypal_email;
                this.billing.name = user.billing_info.name;
                this.billing.account = user.billing_info.account;
                this.billing.address = user.billing_info.address;
                this.billing.city = user.billing_info.city;
                this.billing.postcode = user.billing_info.postcode;
                this.billing.bankName = user.billing_info.bank_name;
                this.billing.swiftCode = user.billing_info.swift_code;
                this.billing.additionalInfo = user.billing_info.additional_info;
            }
        },
        updateDetails() {
            this.resetErrors();
            this.success = false;
            this.loading = true;
            this.axios.post('./user/update-billing-info.json', this.billing).then(() => {
                this.loading = false;
                this.success = true;
                this.$store.commit('set_user', null);
            }).catch(error => {
                this.success = false;
                this.processError(error);
            });
        },
    }
};
</script>
<style lang="scss" scoped>
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('stats-card',{attrs:{"title":"Reward","type":"gradient-yellow","sub-title":_vm.rewardSubtitle(),"icon":"ni ni-money-coins"}},[_c('template',{slot:"footer"},[_c('div',{staticClass:"reward mb-2 mr-2"},[_c('span',{staticClass:"text-success text-xl"},[_vm._v(" $"+_vm._s(Number(Number(_vm.currentMonthReward).toFixed(2)).toLocaleString())+" ")]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-info-circle align-top",attrs:{"title":"Payments are made within  20th day of the calendar month."}}),_c('span',{staticClass:"ml-2 mr-2 text-nowrap",class:{
                        'text-success': _vm.rewardIncrease >= 0,
                        'text-danger': _vm.rewardIncrease < 0
                    }},[_c('i',{class:{
                    'fas fa-arrow-up': _vm.rewardIncrease >= 0,
                    'fas fa-arrow-down': _vm.rewardIncrease < 0
                }}),_vm._v(" "+_vm._s(Number(Math.abs(_vm.rewardIncrease).toFixed(2)).toLocaleString())+"% ")]),_c('span',{staticClass:"text-nowrap"},[_vm._v("Since last "+_vm._s(_vm.$isExclusiveDashboard ? 'quarter' : 'month'))])]),(_vm.monthlyFee > 0)?_c('p',{staticClass:"text-nowrap text-sm"},[_vm._v(" Monthly fee: "),_c('span',{staticClass:"text-success"},[_vm._v("$"+_vm._s(Number(_vm.monthlyFee).toFixed(2)))])]):_vm._e(),_c('p',{staticClass:"text-nowrap text-sm"},[_vm._v(" Minimum payout: "),_c('span',{staticClass:"text-success"},[_vm._v("$50.00")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <list-table :endpoint="endpoint" :fields="fields" :requestParams="requestParams" :showFilters="false">
        <template #header>
            <h3>Payment History & Invoices</h3>
        </template>

        <template #head(status)="data">
            {{ data.label }}
            <i class="fas fa-info-circle align-top"
               title="Payments are made within  20th day of the calendar month."
               v-b-tooltip.hover
            ></i>
        </template>

        <template #cell(total)="data">
            {{ formatTotal(data.item.total) }}
        </template>

        <template #cell(payment_method)="data">
            {{ $constants.billingMethodName[data.item.payment_method] || 'N/A' }}
        </template>

        <template #cell(videos)="data">
            <div class="videos-list">
                <div class="d-inline-block mr-2 mb-1" v-for="(video, index) in getInvoiceVideos(data.item)"
                     v-bind:key="index">
                    <a :href="video.link" target="_blank">
                        <img class="img-fluid" :src="video.thumb" :title="video.title"
                             v-b-tooltip.hover.left>
                    </a>
                </div>
            </div>
        </template>

        <template #cell(invoice)="data">
            <a :href="data.item.url" title="Download" target="_blank">
                <i class="fas fa-download"></i>
            </a>
        </template>

        <template #cell(status)="data">
            {{ data.item.is_paid ? 'Paid' : 'Not paid' }}
        </template>

        <template #cell(agreement_type)="data">
            {{ data.item.is_exclusive ? 'Exclusive' : 'Non-Exclusive' }}
        </template>
    </list-table>
</template>
<script>

import {VBTooltip} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import ListTable from '@/components/ListTable.vue';
import {forEach} from 'lodash';

export default {
    name: 'invoices',
    props: ['type'],
    components: {ListTable},
    directives: {'b-tooltip': VBTooltip},
    mixins: [ProcessError],
    data() {
        return {
            endpoint: './user/invoices/list.json',
            fields: [
                {key: 'date', label: 'Date', sortable: true},
                {key: 'total', label: 'Payment amount', sortable: true, tdClass: 'text-right', thClass: 'text-right'},
                {key: 'payment_method', label: 'Payment method', sortable: false},
                {key: 'videos', label: 'videos', sortable: false},
                {key: 'status', label: 'Status', sortable: false},
                {key: 'invoice', label: 'Invoice', sortable: false},
            ],
        };
    },
    mounted() {
        if (this.type === undefined) {
            this.fields.splice(
                4,
                0,
                {key: 'agreement_type', label: 'Agreement Type', sortable: false},
            );
        }
    },
    computed: {
        requestParams() {
            return '&isExclusive=' + (
                this.type === this.$constants.dashboardType.exclusive
                    ? 1 : (this.type === this.$constants.dashboardType.non_exclusive ? 0 : '')
            );
        },
    },
    methods: {
        formatTotal(total) {
            return '$' + Number(Number(total).toFixed(2)).toLocaleString();
        },
        getInvoiceVideos(invoice) {
            let videos = [];
            forEach(invoice.items, (item) => {
                if (item.type === 'video') {
                    let index = videos.map(video => video.id).indexOf(item.video.id);
                    if (index === -1) {
                        videos.push(item.video);
                    }
                } else if (item.type === 'project') {
                    forEach(item.project.videos, (video) => {
                        let index = videos.map(video => video.id).indexOf(video.id);
                        if (index === -1) {
                            videos.push(video);
                        }
                    })
                }
            })

            return videos;
        },
    }
};
</script>
<style lang="scss" scoped>
.img-fluid {
    max-width: 50px;
}
.videos-list {
    max-width: 400px;
    overflow: hidden;
    line-break: anywhere;
    white-space: break-spaces;
}
</style>

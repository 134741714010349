<template>
    <div>
        <div class="header bg-white py-7 py-lg-8">
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>

        <div class="container mt--8 pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="card bg-secondary shadow-lg border">
                        <div class="card-header bg-transparent px-lg-5">
                            <div class="text-left mt-2">
                                <h3>Your Agreement</h3>
                                <p class="mt-3 mb-0">
                                    Yey, this is the final step! Confirm your agreement and let’s shoot for the stars
                                    together!
                                </p>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <spinner v-model="loading"></spinner>
                            <div class="row">
                                <div class="col-lg-6 col-sm-12">
                                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger">
                                    </b-alert>
                                    <p>
                                        Please, double-check if your agreement details are correct.
                                        If so, confirm the agreement.
                                    </p>
                                    <form role="form">

                                        <base-input class="form-group mb-3"
                                                    label="Your full name"
                                                    v-model="fullName"
                                                    :error="errors.full_name.message"
                                                    @change="changedContract"
                                        ></base-input>

                                        <base-input class="form-group mb-3"
                                                    label="Full address"
                                                    autocomplete="address-line1"
                                                    v-model="fullAddress"
                                                    :error="errors.full_address.message"
                                                    @change="changedContract"
                                        ></base-input>

                                        <template v-if="contract && contract.is_paid_contract">
                                            <div class="form-row">
                                                <div class="form-group col-xl-6 col-lg-12 mb-2">
                                                    <label class="form-control-label">Preferred billing method</label>
                                                    <select class="form-control" v-model="billing.type"
                                                            :class="{'is-invalid': errors.type.message}">
                                                        <option value="">Please select</option>
                                                        <option :value="1">PayPal</option>
                                                        <option :value="2">I don't have PayPal</option>
                                                    </select>
                                                    <div class="invalid-feedback text-danger" v-if="errors.type.message"
                                                         v-text="errors.type.message"></div>
                                                </div>
                                            </div>
                                            <div class="form-row"
                                                 v-if="parseInt(billing.type) === $constants.billingMethod.paypal">
                                                <base-input
                                                    v-model="billing.paypalEmail"
                                                    type="email"
                                                    :label="'PayPal email'"
                                                    :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                    :error="errors.paypal_email.message"
                                                ></base-input>
                                            </div>
                                            <div v-if="parseInt(billing.type) === $constants.billingMethod.bank">
                                                <div class="form-row">
                                                    <div class="form-group col-md-12 mb-2">
                                                        <label class="form-control-label">Your Legal Status</label>
                                                        <base-radio :name="1" v-model="billing.legalStatus"
                                                                    :class="{'is-invalid': errors.legal_status.message}">
                                                            Individual
                                                        </base-radio>
                                                        <base-radio :name="2" v-model="billing.legalStatus"
                                                                    :class="{'is-invalid': errors.legal_status.message}">
                                                            Company
                                                        </base-radio>
                                                        <div class="invalid-feedback text-danger" v-if="errors.legal_status.message"
                                                             v-text="errors.legal_status.message"></div>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <base-input
                                                        v-model="billing.name"
                                                        :label="'Beneficiary\'s name'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.name.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.account"
                                                        :label="'Beneficiary\'s account'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.account.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.address"
                                                        :label="'Beneficiary\'s address'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.address.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.city"
                                                        :label="'Beneficiary\'s city'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.city.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.postcode"
                                                        :label="'Beneficiary\'s postcode'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.postcode.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.bankName"
                                                        :label="'Beneficiary\'s bank name'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.bank_name.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.swiftCode"
                                                        :label="'Beneficiary SWIFT'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.swift_code.message"
                                                    ></base-input>
                                                    <base-input
                                                        v-model="billing.additionalInfo"
                                                        :label="'Additional info'"
                                                        :class="'form-group col-xl-6 col-lg-12 mb-2'"
                                                        :error="errors.additional_info.message"
                                                    ></base-input>
                                                </div>
                                            </div>
                                        </template>

                                        <div class="row my-4">
                                            <div class="col-12">
                                                <base-checkbox
                                                    :error="errors.contract_agreed.message"
                                                    v-model="contractAgreed"
                                                    class="custom-control-alternative">
                                                    <span class="text-muted">
                                                         I have read, fully understood and agree to be bound by this
                                                        <a :href="contractUrl" target="_blank">Agreement</a>
                                                    </span>
                                                </base-checkbox>
                                            </div>
                                        </div>
                                        <div class="text-left">
                                            <base-button @click="confirm" type="primary" class="my-4">
                                                Confirm
                                            </base-button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-lg-6 d-none d-lg-block">
                                    <pdf-viewer :src="contractUrl"></pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PdfViewer from '@/components/PdfViewer.vue';
import Spinner from '@/components/Spinner.vue';
import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    name: 'agreement',
    components: {PdfViewer, Spinner, BAlert},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            id: null,
            fullName: null,
            fullAddress: null,
            contractAgreed: false,
            contract: null,
            tempContract: false,
            errors: {
                form: {message: '', show: false},
                full_name: {message: ''},
                full_address: {message: ''},
                contract_agreed: {message: ''},
                type: {message: ''},
                legal_status: {message: ''},
                paypal_email: {message: ''},
                name: {message: ''},
                account: {message: ''},
                address: {message: ''},
                city: {message: ''},
                postcode: {message: ''},
                bank_name: {message: ''},
                swift_code: {message: ''},
                additional_info: {message: ''},
            },
            billing: {
                legalStatus: '',
                type: '',
                paypalEmail: '',
                name: '',
                account: '',
                address: '',
                city: '',
                postcode: '',
                bankName: '',
                swiftCode: '',
                additionalInfo: '',
            },
        }
    },
    computed: {
        contractUrl() {
            return this.contract ? this.contract.contract_url : null;
        },
    },
    mounted() {
        this.fetchUserContract();
        this.id = this.$auth.user().id;
        this.name = this.$auth.user().name;
        this.address = this.$auth.user().address;
    },
    methods: {
        updatePhoneNumber(object) {
            this.phoneNumberObject = object;
        },
        resetErrors() {
            Object.assign(this.$data.errors, this.$options.data().errors);
        },
        fetchUserContract() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./user/contracts/list.json').then(x => x.data).then((contracts) => {
                this.contract = contracts[0];
                this.loading = false;
            }).catch(this.processError);
        },
        changedContract() {
            if (this.contract && this.contract.is_custom) {
                return;
            }
            this.generateTemporaryContract();
        },
        generateTemporaryContract() {
            this.loading = true;
            this.axios.post('./user/contracts/generate-temporary.json', {
                id: this.contract.id,
                name: this.name,
                address: this.address,
                contract_url: this.tempContract,
            }).then(x => x.data).then((data) => {
                this.contract.contract_url = data.url;
                this.tempContract = data.url;
                this.loading = false;
            }).catch(this.processError);
        },
        confirm() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./user/contracts/process-agreement.json', {
                full_name: this.fullName,
                full_address: this.fullAddress,
                contract_agreed: this.contractAgreed,
                phone_number: this.phoneNumberObject,
                temp_contract: this.tempContract || '',
                ...this.billing
            }).then(() => {
                this.$ga.event('User', 'Agreed', this.id.toString());
                this.$store.commit('set_user', null);
                this.$store.dispatch('showRegisterAlert');
                this.$router.push('dashboard');
            }).catch(this.processError);
        },
    },
}
</script>
<style lang="scss" scoped>
.custom-index {
    z-index: 0;
}
</style>

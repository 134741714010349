<template>
    <form class="form" autocomplete="off" ref="passwordForm">
        <card>
            <h3 class="mb-3">Change Your Password</h3>
            <spinner v-model="loading"></spinner>
            <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
            <b-alert v-model="success" variant="success">
                Your password has been successfully updated!
            </b-alert>
            <base-input class="form-group mb-3"
                        label="Enter your current password"
                        type="password"
                        autocomplete="new-password"
                        :error="errors.current_password.message"
                        v-model="user.currentPassword">
            </base-input>

            <base-input class="form-group mb-3"
                        label="Enter your new password"
                        type="password"
                        autocomplete="new-password"
                        :error="errors.password.message"
                        v-model="user.password">
            </base-input>

            <base-input class="form-group mb-3"
                        label="Confirm your new password"
                        type="password"
                        autocomplete="new-password"
                        :error="errors.confirmed_password.message"
                        v-model="user.confirmedPassword">
            </base-input>

            <div>
                <button type="button" @click="updatePassword" class="btn btn-primary">
                    Save changes
                </button>
            </div>
        </card>
    </form>
</template>
<script>

import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';
import {BAlert} from 'bootstrap-vue';

export default {
    name: 'password-change',
    components: {Spinner, BAlert},
    mixins: [ProcessError],
    data() {
        return {
            user: {
                currentPassword: '',
                password: '',
                confirmedPassword: '',
            },
            errors: {
                form: {show: false, message: ''},
                current_password: {message: ''},
                password: {message: ''},
                confirmed_password: {message: ''},
            },
            loading: false,
            success: false,
        };
    },
    methods: {
        updatePassword() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./user/update-password.json', this.user).then(() => {
                this.loading = false;
                this.success = true;
                this.user.password = '';
                this.user.currentPassword = '';
                this.user.confirmedPassword = '';
            }).catch(error => {
                this.processError(error);
            });
        },
    }
};
</script>
<style lang="scss" scoped>

</style>

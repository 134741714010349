<template>
    <div>
        <spinner v-model="loading"></spinner>
        <b-alert v-model="error" variant="danger">
            Error! Please try again!
        </b-alert>
        <b-alert v-model="success" variant="success">
            Success!
        </b-alert>
        <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :sortDesc="false" :sortKeys="sortKeys">
            <template #header>
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <button type="button" class="btn btn-sm btn-danger" @click="exportArtists">
                            Export
                        </button>
                        <button type="button" class="btn btn-sm btn-primary" @click="addArtist">
                            Add artist
                        </button>
                        <new-artist @after-submit="afterSubmit"></new-artist>
                    </div>
                </div>
            </template>
            <template #cell(status)="data">
                {{ data.item.is_active ? 'Active' : 'Inactive' }}
                <span v-if="!data.item.is_contract_agreed"> | Not registered
                    <a href="javascript:void(0)" class="ml-1" title="Remind Invitation"
                       v-if="!data.item.is_active"
                       @click.prevent="remindUser(data.item)">
                        <i class="far fa-bell"></i>
                    </a>
                </span>
            </template>

            <template #cell(artist_id)="data">
                <a v-if="data.item.artist" target="_blank"
                   :href="'https://app.pipelinecrm.com/people/' + data.item.artist.artist_id">
                    {{ data.item.artist.artist_id }}
                </a>
            </template>

            <template #cell(videos)="data">
                    <span v-if="data.item.artist">
                        {{ data.item.artist.videos_count }}
                    </span>
            </template>

            <template #cell(role)="data">
                {{ data.item.is_exclusive ? 'Exclusive User' : 'User' }}
            </template>

            <template #cell(actions)="data">
                <a href="#" class="ml-2" title="Manage contracts"
                   @click.prevent="manageContracts(data.item)">
                    <i class="fas fa-file-contract"></i>
                </a>
                <a href="javascript:void(0)" class="ml-3" title="Edit artist"
                   @click.prevent="editUser(data.item)">
                    <i class="fas fa-edit"></i>
                </a>
                <a href="javascript:void(0)" class="ml-3 text-danger" title="Delete artist"
                   v-if="!data.item.is_active && !data.item.is_contract_agreed"
                   @click.prevent="deleteUser(data.item)">
                    <i class="fas fa-trash-alt"></i>
                </a>
                <a v-if="data.item.is_active" href="javascript:void(0)" class="ml-3" title="Login as artist"
                   @click.prevent="loginAsArtist(data.item)">
                    <i class="fas fa-sign-in-alt"></i>
                </a>
            </template>
        </list-table>
        <edit-artist :user="user" @after-submit="afterSubmit"></edit-artist>
        <manage-contracts :user="user" @after-submit="afterSubmit"></manage-contracts>
    </div>
</template>

<script>

import NewArtist from './NewArtist.vue';
import ListTable from '@/components/ListTable.vue';
import Spinner from '@/components/Spinner';
import {BAlert} from 'bootstrap-vue';
import EditArtist from '@/views/Artists/EditArtist.vue';
import ManageContracts from '@/views/Artists/ManageContracts.vue';
import DownloadFile from '@/mixins/DownloadFile';

export default {
    name: 'list-artists',
    components: {ListTable, NewArtist, EditArtist, ManageContracts, Spinner, BAlert},
    mixins: [DownloadFile],
    data() {
        return {
            endpoint: './admin/artists/list.json',
            user: null,
            loading: false,
            success: false,
            error: false,
            fields: [
                {key: 'id', label: '#', sortable: true},
                {key: 'name', label: 'Name', sortable: true},
                {key: 'email', label: 'Email', sortable: false},
                {key: 'artist_id', label: 'Artist id', sortable: false},
                {key: 'artist.name', label: 'Artist name', sortable: false},
                {key: 'videos', label: 'Videos', sortable: false, tdClass: 'text-right'},
                {key: 'created_at', label: 'Date added', sortable: true},
                {key: 'status', label: 'Status', sortable: true},
                {key: 'role', label: 'Role', sortable: false},
                {key: 'account_manager.name', label: 'Account manager', sortable: false},
                {key: 'department.name', label: 'Channel', sortable: false},
                {key: 'actions', label: '', sortable: false},
            ],
            sortKeys: {
                'id': 'id',
                'name': 'name',
                'email': 'email',
                'created_at': 'created_at',
                'status': 'is_active',
            },
        }
    },
    methods: {
        editUser(user) {
            this.user = user;
            this.$bvModal.show('edit-artist');

        },
        deleteUser(user) {
            this.$confirm.require(
                {
                    message: `Are you sure want to delete this user?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.loading = true;
                        this.axios.post('./admin/artists/delete.json', {
                            user_id: user.id,
                        }).then(() => {
                            this.success = true;
                            this.loading = false;
                            const indexToDelete = this.$refs.listTable.entries.findIndex(u => u.id === user.id);
                            if (indexToDelete !== -1) {
                                this.$refs.listTable.entries.splice(indexToDelete, 1);
                            }
                        }).catch(() => {
                            this.error = false;
                            this.loading = false;
                        });
                    }
                }
            );
        },
        manageContracts(user) {
            this.user = user;
            this.$bvModal.show('manage-contracts');
        },
        remindUser(user) {
            this.$confirm.require(
                {
                    message: `Are you sure want to send reminder to this user?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.loading = true;
                        this.axios.post('./admin/artists/remind-invitation.json', {
                            user_id: user.id,
                        }).then(() => {
                            this.success = true;
                            this.loading = false;
                        }).catch(() => {
                            this.error = false;
                            this.loading = false;
                        });
                    }
                }
            );
        },
        addArtist() {
            this.$bvModal.show('artist-modal');
        },
        afterSubmit() {
            this.$refs.listTable.searchEntries();
        },
        loginAsArtist(user) {
            this.$confirm.require(
                {
                    message: `Are you sure want to login as this user?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.axios.post('./admin/artists/login.json', {
                            user_id: user.id,
                            artist_id: user.artist.artist_id
                        }).then(x => x.data).then(() => {
                            window.location.href = '/dashboard';
                        });
                    }
                }
            );
        },
        exportArtists() {
            this.axios.post('./admin/artists/export.json', {}, {
                responseType: 'arraybuffer'
            }).then(x => x.data).then(response => this.downloadFile(response, 'artists.csv')).catch(error => {
                console.log(error);
            });
        },
    }
};
</script>

<style lang="scss" scoped>

</style>

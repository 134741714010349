import {forEach} from 'lodash';

export default {
    data() {
        return {
            errors: {},
            loading: false,
        };
    },
    methods: {
        resetErrors() {
            Object.assign(this.$data.errors, this.$options.data().errors);
        },
        processError(error) {
            if (error.response.status === 422) {
                forEach(error.response.data.errors, (message, field) => {
                    if (field.startsWith('phone_number')) {
                        field = 'phone_number';
                    }
                    if (typeof this.errors[field] !== 'undefined') {
                        this.errors[field].message = message[0];
                    } else {
                        this.errors.form.message = message[0];
                        this.errors.form.show = true;
                    }
                });
            } else if (typeof error.response.data.message !== 'undefined') {
                this.errors.form.message = error.response.data.message;
                this.errors.form.show = true;
            }
            this.loading = false;
        },
    },
}
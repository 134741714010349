<template>
    <div class="full-page">
        <invoices></invoices>
    </div>
</template>
<script>

import Invoices from '@/views/Rewards/Invoices.vue';

export default {
    components: {Invoices},
    data() {
        return {
            user: null,
        };
    },
};
</script>
<style lang="scss" scoped>
</style>

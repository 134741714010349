import { render, staticRenderFns } from "./ListArtists.vue?vue&type=template&id=275cd949&scoped=true"
import script from "./ListArtists.vue?vue&type=script&lang=js"
export * from "./ListArtists.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275cd949",
  null
  
)

export default component.exports
<template>
    <div class="full-page">
        <div class="row">
            <div class="col-lg-6 col-sm-12 mb-5">
                <contact-info></contact-info>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-12 mb-5">
                        <billing></billing>
                    </div>
                    <div class="col-12 mb-5">
                        <password-change></password-change>
                    </div>
                    <div class="col-12 mb-5">
                        <notifications></notifications>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Notifications from '@/views/Details/Notifications.vue';
import PasswordChange from '@/views/Details/PasswordChange.vue';
import ContactInfo from '@/views/Details/ContactInfo.vue';
import Billing from '@/views/Rewards/Billing.vue';


export default {
    components: {ContactInfo, Notifications, PasswordChange, Billing},
};
</script>
<style lang="scss" scoped>

</style>

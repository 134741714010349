<template>
    <form class="form">
        <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger">
        </b-alert>
        <spinner v-model="loading"></spinner>
        <card>
            <h3 class="mb-3">Notifications by Email</h3>
            <div class="mb-3">
                Select what type of updates you would like to receive in your inbox:
            </div>
            <div class="form-group mb-3 clearfix">
                <base-switch v-model="notifications.accountUpdates" disabled>
                </base-switch>
                <b>Important account updates</b>
                <p>
                    From time to time we will need to send you important information about your account,
                    agreement or payment information. It is not possible to opt out of receiving these.
                </p>
            </div>
            <div class="form-group mb-0 clearfix">
                <base-switch v-model="notifications.engagement"
                             @input="updateNotifications">
                </base-switch>
                <b>Entertaining updates</b>
                <p>
                    We don't love spam either, so we'll send you only the most important milestones on your videos'
                    performance. But it's up to you.
                </p>
            </div>
        </card>
    </form>
</template>
<script>

import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';
import {BAlert} from 'bootstrap-vue';

export default {
    name: 'notifications',
    components: {Spinner, BAlert},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            notifications: {
                engagement: false,
                promotion: false,
                accountUpdates: true,
            },
            errors: {
                form: {show: false, message: ''},
            }
        };
    },
    mounted() {
        this.getNotifications();
    },
    methods: {
        getNotifications() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./user/notifications/list.json').then(x => x.data).then((notifications) => {
                this.notifications.engagement = notifications.engagement || false;
                this.notifications.promotion = notifications.promotion || false;
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
        updateNotifications() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./user/notifications/update.json', this.notifications).then(() => {
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
    },
};
</script>
<style lang="scss" scoped>

</style>

<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <list-artists></list-artists>
        </div>
    </div>
</template>
<script>

import ListArtists from './Artists/ListArtists.vue';

export default {
    components: {
        ListArtists,
    },
};
</script>

<style lang="scss" scoped></style>

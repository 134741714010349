<template>
    <div class="full-page">
        <div class="row">
            <div class="col-lg-12 col-sm-12 mb-5">
                <card>
                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                    <b-alert v-model="success" variant="success">Thank you! Your agreement has been updated!</b-alert>
                    <spinner v-model="loading"></spinner>

                    <div v-if="activeContract" class="row pb-5">
                        <div class="col-lg-12">
                            <h2 class="heading-section text-capitalize pb-3">My latest agreement</h2>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <pdf-viewer :position="'left'" :size="'lg'" :src="activeContract.contract_url"></pdf-viewer>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <dl class="row">
                                <dt class="col-sm-4">Validity</dt>
                                <dd class="col-sm-8">
                                    {{ activeContract.start_date }} - {{ activeContract.end_date }}
                                    <span v-if="activeContract.auto_renewal && activeContract.renewed_at"
                                          class="text-muted" :title="activeContract.renewed_at" v-b-tooltip.hover>
                                        (Renewed)
                                    </span>
                                </dd>

                                <dt class="col-sm-4">Status</dt>
                                <dd class="col-sm-8">
                                    {{ activeContract.status_name }}
                                    <a :href="activeContract.contract_url" target="_blank" class="ml-3"
                                       title="Download">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </dd>

                                <dt class="col-sm-4">Agreement type</dt>
                                <dd class="col-sm-8">{{ activeContract.contract_type_name }}</dd>

                                <dt class="col-sm-4">Payment type</dt>
                                <dd class="col-sm-8">{{ activeContract.payment_type_public_name }}</dd>
                            </dl>
                            <div class="pt-3" v-if="activeContract.status === $constants.contractStatus.new">
                                <base-checkbox class="custom-control-alternative" v-model="activeContract.agreed">
                                            <span class="text-muted">
                                                I have read, fully understood and agree to be bound by this
                                                <a :href="activeContract.contract_url" target="_blank">Agreement</a>
                                            </span>
                                </base-checkbox>
                                <button type="button" class="btn btn-primary mt-2"
                                        @click="signContract(activeContract)">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="previousContracts.length > 0" class="row pb-5">
                        <div class="col-lg-12">
                            <h2 class="heading-section text-capitalize pb-3">My previous agreements</h2>
                        </div>
                        <div class="col-lg-4 col-sm-6" v-for="contract in previousContracts" v-bind:key="contract.id">
                            <div class="row expired-contract">
                                <div class="col-6">
                                    <pdf-viewer :position="'left'" :src="contract.contract_url"></pdf-viewer>
                                </div>
                                <div class="col-6">
                                    <dl class="row mb-0">
                                        <dt class="col-12">Validity</dt>
                                        <dd class="col-12">
                                            {{ contract.start_date }} - {{ contract.end_date }}
                                            <span v-if="contract.auto_renewal && contract.renewed_at"
                                                  class="text-muted" :title="contract.renewed_at" v-b-tooltip.hover>
                                                (Renewed)
                                            </span>
                                        </dd>

                                        <dt class="col-12">Status</dt>
                                        <dd class="col-12">
                                            {{ contract.status_name }}
                                            <a :href="contract.contract_url" target="_blank" class="ml-3"
                                               title="Download">
                                                <i class="fas fa-download"></i>
                                            </a>
                                        </dd>

                                        <dt class="col-12">Agreement type</dt>
                                        <dd class="col-12">{{ contract.contract_type_name }}</dd>

                                        <dt class="col-12">Payment type</dt>
                                        <dd class="col-12">{{ contract.payment_type_public_name }}</dd>
                                    </dl>
                                    <div v-if="contract.status === $constants.contractStatus.new">
                                        <base-checkbox class="custom-control-alternative" v-model="contract.agreed">
                                            <span class="text-muted">
                                                I have read, fully understood and agree to be bound by this
                                                <a :href="contract.contract_url" target="_blank">Agreement</a>
                                            </span>
                                        </base-checkbox>
                                        <button type="button" class="btn btn-sm btn-primary mt-2"
                                                @click="signContract(contract)">
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <h6 class="heading-small">Bored Panda Studios documents</h6>
                            <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                            <br>
                            <a target="_blank" href="/terms-and-conditions">Terms and Conditions</a>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            Have any questions about your agreement? Don’t hesitate to
                            <a href="mailto:partnership@boredpanda.com">contact us!</a>
                        </div>
                    </div>
                </card>

                <b-toast id="signToast" v-if="showSignToast" toaster="b-toaster-top-center" variant="success"
                         title="Congratulations on successful signing in!" visible no-auto-hide no-close-button>
                    Last step - please fill in your billing details
                    <a href="/my-details" target="_blank" class="alert-link text-success">here.</a> They are necessary
                    to process your transactions
                    <div class="text-right">
                        <button class="btn btn-sm btn-primary" @click="hideToast">Close</button>
                    </div>
                </b-toast>
            </div>
        </div>
    </div>
</template>
<script>

import PdfViewer from '@/components/PdfViewer.vue';
import Spinner from '@/components/Spinner.vue';
import {BAlert, VBTooltip, BToast} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    components: {PdfViewer, Spinner, BAlert, BToast},
    directives: {'b-tooltip': VBTooltip},
    mixins: [ProcessError],
    data() {
        return {
            user: null,
            activeContract: null,
            contracts: null,
            loading: false,
            success: false,
            errors: {
                form: {show: false, message: ''},
            },
            showSignToast: false,
        };
    },
    computed: {
        previousContracts() {
            if (this.contracts) {
                return this.contracts.filter((contract) => {
                    return !this.activeContract || contract.id !== this.activeContract.id;
                });
            } else {
                return [];
            }
        },
    },
    async mounted() {
        this.user = await this.$store.dispatch('getUser');
        this.activeContract = this.user.active_contract;
        this.fetchContracts();
    },
    methods: {
        fetchContracts() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./user/contracts/list.json').then(x => x.data).then((contracts) => {
                this.contracts = contracts;
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
        signContract(contract) {
            this.resetErrors();
            if (!contract.agreed) {
                this.errors.form.message = 'Please agree with contract before signing.';
                this.errors.form.show = true;
                return;
            }
            this.loading = true;
            this.axios.post('./user/contracts/sign.json', {
                contract_id: contract.id
            }).then(x => x.data).then(() => {
                contract.status = this.$constants.contractStatus.agreed;
                contract.status_name = this.$constants.contractStatusName[contract.status];
                this.success = true;
                this.loading = false;
                if (!this.user.has_billing_info && contract.payment_type !== this.$constants.paymentType.free) {
                    this.showSignToast = true;
                }
            }).catch(error => {
                this.processError(error);
            });
        },
        hideToast() {
            this.showSignToast = false;
        },
    }
};
</script>
<style lang="scss" scoped>
.expired-contract {
    font-size: 12px;

    .text-muted {
        font-size: 12px;
    }
}
</style>

<template>
    <div class="container pt-lg-5">
        <div class="row pt-8">
            <div class="col-lg-5 col-sm-12 order-first order-lg-last text-center">
                <img class="img-fluid" src="img/brand/panda_landing_camera.png">
            </div>
            <div class="col-lg-7 col-sm-12 pb-5">
                <h1 class="pb-4 text-secondary">Bored Panda Studios Partnership Platform</h1>
                <p class="text-secondary">
                    Hey! We're Bored Panda Studios, one of the biggest social media video producers
                    & publishers in the world. We own & operate more than 100 social media channels that generate
                    over 25 billion views per year!
                </p>
                <p class="text-secondary">
                    We believe your hard work deserves to be recognized globally and we're eager to help you
                    reach this goal!
                </p>
                <p class="text-secondary">
                    Here you can submit your inquiries about the video creators we are working with. Due to the
                    significant amount of videos we publish on a daily basis, please indicate the exact video you
                    would like to get information about in the message section.
                </p>
                <div class="pt-4">
                    <button class="btn btn-success" type="button" @click="openContactForm">Send message</button>
                    <contact-form></contact-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import ContactForm from "@/views/Homepage/ContactForm";

export default {
    name: 'homepage',
    components: {ContactForm},
    data() {
        return {
            model: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        openContactForm() {
            this.$bvModal.show('contact-form');
        }
    }
}
</script>
<style lang="scss" scoped>
.bottom {
    bottom: 0;
}
</style>

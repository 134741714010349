<template>
    <form class="form" autocomplete="off" ref="detailsForm">
        <card>
            <h3 class="mb-3">Contact Details</h3>
            <div class="mb-3">
                Please make sure that all the contact details are correct and up to date
            </div>
            <spinner v-model="loading"></spinner>
            <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger">
            </b-alert>
            <b-alert v-model="success" variant="success">
                Your details has been successfully updated!
            </b-alert>
            <base-input class="form-group mb-3"
                        label="Your full name"
                        :error="errors.name.message"
                        v-model="user.name">
            </base-input>

            <base-input class="form-group mb-3"
                        label="Email"
                        disabled="disabled"
                        v-model="user.email">
            </base-input>

            <base-input class="form-group mb-3"
                        label="Secondary email"
                        :error="errors.secondary_email.message"
                        v-model="user.secondaryEmail">
            </base-input>

            <base-country class="form-group mb-3"
                          label="Billing country"
                          autocomplete="off"
                          ref="billingCountry"
                          :error="errors.billing_country.message"
                          v-model="user.billingCountry">
            </base-country>

            <base-input class="form-group mb-3"
                        label="Address"
                        :error="errors.address.message"
                        v-model="user.address">
            </base-input>

            <div class="form-group mb-3">
                <label class="form-control-label">Phone number</label>
                <vue-phone-number-input v-model="user.phoneNumberValue"
                                        :default-country-code="user.phoneNumber ? user.phoneNumber.country_code : null"
                                        :no-example="true"
                                        @update="updatePhoneNumber"
                                        ref="phoneNumber"
                                        :error="errors.phone_number.message !== ''"
                                        :class="{'is-invalid': errors.phone_number.message}"
                ></vue-phone-number-input>
                <div class="invalid-feedback text-danger" v-if="errors.phone_number.message"
                     v-text="errors.phone_number.message"></div>
            </div>

            <div class="form-group mb-3">
                <base-checkbox class="custom-index" v-model="user.phoneApps.telegram">
                    Telegram
                </base-checkbox>
                <base-checkbox class="custom-index" v-model="user.phoneApps.whatsapp">
                    Whatsapp
                </base-checkbox>
                <base-checkbox class="custom-index" v-model="user.phoneApps.signal">
                    Signal
                </base-checkbox>
                <base-checkbox class="custom-index" v-model="user.phoneApps.phone">
                    Phone
                </base-checkbox>
            </div>

            <base-input
                :label="'Facebook'"
                :class="'form-group mb-3'"
                :error="errors.facebook.message"
                v-model="user.facebook"
                placeholder="https://www.facebok.com/..."
            ></base-input>

            <base-input
                :label="'Instagram'"
                :class="'form-group mb-3'"
                :error="errors.instagram.message"
                v-model="user.instagram"
                placeholder="https://www.instagram.com/..."
            ></base-input>

            <base-input
                :label="'Youtube'"
                :class="'form-group mb-3'"
                :error="errors.youtube.message"
                v-model="user.youtube"
                placeholder="https://www.youtube.com/..."
            ></base-input>

            <div>
                <button type="button" @click="updateDetails" class="btn btn-primary">
                    Save changes
                </button>
            </div>
        </card>
    </form>
</template>
<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';
import {BAlert} from 'bootstrap-vue';

export default {
    name: 'contact-info',
    components: {Spinner, VuePhoneNumberInput, BAlert},
    mixins: [ProcessError],
    data() {
        return {
            user: {
                name: '',
                email: '',
                secondaryEmail: '',
                phoneNumber: null,
                phoneNumberValue: null,
                billingCountry: null,
                address: '',
                phoneApps: {
                    telegram: null,
                    whatsapp: null,
                    signal: null,
                    phone: null,
                },
                facebook: '',
                instagram: '',
                youtube: '',
            },
            errors: {
                form: {show: false, message: ''},
                name: {message: ''},
                secondary_email: {message: ''},
                phone_number: {message: ''},
                billing_country: {message: ''},
                address: {message: ''},
                youtube: {message: ''},
                instagram: {message: ''},
                facebook: {message: ''},
            },
            loading: false,
            success: false,
        };
    },
    mounted() {
        this.$store.dispatch('getUser').then((user) => {
            this.initUser(user);
        });
    },
    methods: {
        updatePhoneNumber(object) {
            this.user.phoneNumber = object;
        },
        initUser(user) {
            this.user.name = user.name;
            this.user.email = user.email;
            this.user.secondaryEmail = user.secondary_email;
            this.user.phoneNumber = user.phone_number;
            this.user.phoneNumberValue = user.phone_number ? user.phone_number.phone_number : null;
            this.user.billingCountry = user.billing_country;
            this.user.address = user.address;
            this.user.phoneApps.whatsapp = !!user.whatsapp;
            this.user.phoneApps.telegram = !!user.telegram;
            this.user.phoneApps.signal = !!user.signal;
            this.user.phoneApps.phone = !!user.phone;
            this.user.facebook = user.facebook;
            this.user.instagram = user.instagram;
            this.user.youtube = user.youtube;
            if (typeof this.user.billingCountry === 'string') {
                const index = this.$refs.billingCountry.billingCountries.findIndex(
                    c => c.code === this.user.billingCountry
                );
                if (index !== -1) {
                    this.user.billingCountry = this.$refs.billingCountry.billingCountries[index]
                }
            }
        },
        updateDetails() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./user/update-details.json', this.user).then(() => {
                this.loading = false;
                this.success = true;
                this.scrollTop();
                this.$store.commit('set_user', null);
            }).catch(error => {
                this.processError(error);
                this.scrollTop();
            });
        },
        scrollTop() {
            window.scroll({top: 0, left: 0, behavior: 'smooth'});
        },
    }
};
</script>
<style lang="scss" scoped>
.custom-control {
    z-index: 0;
}
</style>

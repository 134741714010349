<template>
    <div>
        <b-modal id="edit-artist" scrollable hide-footer title="Edit artist">
            <div class="row">
                <spinner v-model="loading"></spinner>
                <div class="col-lg-12" v-if="user">
                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                    <div class="form-group mb-2">
                        <a :href="'https://app.pipelinecrm.com/people/' + user.artist.artist_id" target="_blank">
                            {{ user.artist.name }}
                        </a>
                    </div>

                    <base-input
                        v-model="userData.name"
                        :label="'Full name'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.name.message"
                    ></base-input>

                    <base-input
                        v-model="userData.email"
                        :label="'Email'"
                        :disabled="true"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.email.message"
                    ></base-input>

                    <base-input
                        v-model="userData.address"
                        :label="'Address'"
                        :input-classes="'form-control form-control-sm'"
                        :class="'form-group mb-2'"
                        :error="errors.address.message"
                    ></base-input>

                    <div class="form-group mb-2">
                        <label class="form-control-label">BP channel</label>
                        <multiselect
                            v-model="userData.channel"
                            :options="channels"
                            label="name"
                            track-by="value"
                            placeholder="Choose..."
                            :class="[{'is-invalid': errors.channel.message}]"
                        ></multiselect>
                        <div class="invalid-feedback text-danger"
                             v-if="errors.channel.message"
                             v-text="errors.channel.message"
                        ></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Currency</label>
                        <multiselect
                            v-model="userData.currency"
                            :options="currencies"
                            label="name"
                            track-by="value"
                            placeholder="Choose..."
                            :class="[{'is-invalid': errors.currency.message}]"
                        ></multiselect>
                        <div class="invalid-feedback text-danger"
                             v-if="errors.currency.message"
                             v-text="errors.currency.message"
                        ></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Role</label>
                        <base-radio name="1" v-model="userData.isExclusive"
                                    :class="{'is-invalid': errors.is_exclusive.message}">
                            Exclusive User
                        </base-radio>
                        <base-radio name="0" v-model="userData.isExclusive"
                                    :class="{'is-invalid': errors.is_exclusive.message}">
                            User
                        </base-radio>
                        <div class="invalid-feedback text-danger" v-if="errors.is_exclusive.message"
                             v-text="errors.is_exclusive.message"></div>
                    </div>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Status</label>
                        <base-radio name="1" v-model="userData.isActive"
                                    :class="{'is-invalid': errors.is_active.message}">
                            Active
                        </base-radio>
                        <base-radio name="0" v-model="userData.isActive"
                                    :class="{'is-invalid': errors.is_active.message}">
                            Inactive
                        </base-radio>
                        <div class="invalid-feedback text-danger" v-if="errors.is_active.message"
                             v-text="errors.is_active.message"></div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="$bvModal.hide('edit-artist')" class="btn btn-light mt-3">
                        Close
                    </button>
                    <button type="button" @click="update" class="btn btn-primary mt-3">Update</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

import {filter} from 'lodash';
import constants from '@/constants';
import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'edit-artist',
    components: {Spinner, BAlert},
    mixins: [ProcessError],
    props: {
        user: {
            type: Object,
            description: 'Artist to edit',
        },
    },
    data() {
        return {
            errors: {
                form: {show: false, message: ''},
                contract_type: {message: ''},
                date: {message: ''},
                name: {message: ''},
                email: {message: ''},
                address: {message: ''},
                is_active: {message: ''},
                is_exclusive: {message: ''},
                currency: {message: ''},
                channel: {message: ''},
            },
            loading: false,
            userData: {
                name: null,
                email: null,
                address: null,
                isActive: null,
                isExclusive: null,
                currency: null,
                channel: null,
            },
            currencies: [
                {
                    value: constants.currencies.usd,
                    name: constants.currencyName[constants.currencies.usd]
                },
                {
                    value: constants.currencies.eur,
                    name: constants.currencyName[constants.currencies.eur]
                },
                {
                    value: constants.currencies.gbp,
                    name: constants.currencyName[constants.currencies.gbp]
                },
            ],
            channels: [
                {
                    value: constants.channels.craftyPanda,
                    name: constants.channelName[constants.channels.craftyPanda]
                },
                {
                    value: constants.channels.ladyPanda,
                    name: constants.channelName[constants.channels.ladyPanda]
                },
            ],
        }
    },
    watch: {
        user() {
            this.initUser();
        }
    },
    methods: {
        initUser() {
            this.userData.id = this.user.id;
            this.userData.name = this.user.name;
            this.userData.email = this.user.email;
            this.userData.address = this.user.address;
            this.userData.isActive = this.user.is_active;
            this.userData.isExclusive = this.user.is_exclusive;
            this.userData.channel = filter(this.channels, (channel) => {
                return channel.value === this.user.department_id;
            })[0] || null;
            this.userData.currency = filter(this.currencies, (currency) => {
                return currency.value === this.user.currency;
            })[0] || null;
        },
        update() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./admin/artists/update.json', this.userData).then(x => x.data).then(() => {
                this.$bvModal.hide('edit-artist');
                this.loading = false;
                this.$emit('after-submit');
            }).catch(error => {
                this.processError(error);
            });
        },
    }
};
</script>

<style lang="scss" scoped>
</style>

<template>
    <div>
        <b-modal id="contract-videos-modal" scrollable size="xl" hide-footer>
            <div class="row">
                <div class="col-lg-12">
                    <spinner v-model="loading"></spinner>
                    <b-alert v-model="errors.show" v-text="errors.message" variant="danger">
                    </b-alert>
                </div>
            </div>
            <div class="row">
                <div class="table-responsive">

                    <b-table id="contract-videos-table" class="table" thead-class="thead-light"
                             v-if="!isEmpty"
                             sort-icon-right
                             :current-page="page"
                             :items="videos"
                             :fields="fields"
                    >
                        <template #cell(video)="data">
                            <a :href="data.item.video.link" target="_blank">
                                <img class="img-fluid" :src="data.item.video.thumb" :title="data.item.video.title"
                                     v-b-tooltip.right.hover>
                            </a>
                        </template>

                        <template #cell(actions)="data">
                            <a href="#" class="ml-2" title="Edit video"
                               @click.prevent="editVideo(data.item)">
                                <i class="fas fa-edit"></i>
                            </a>
                        </template>

                    </b-table>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="closeModal" class="btn btn-light mt-3">
                        Close
                    </button>
                </div>
            </div>
        </b-modal>

        <b-modal id="video-edit-modal" size="sm" hide-footer>
            <template #modal-title>
                <h5 class="modal-title" v-text="selectedVideo.title"></h5>
            </template>
            <multiselect
                v-model="selectedVideo.status"
                :options="statuses"
                :multiple="false"
                label="name"
                track-by="id"
                :show-labels="true"
                placeholder="Choose..."
            ></multiselect>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="closeVideoEditModal()" class="btn btn-light mt-3">Close
                    </button>
                    <button type="button" @click="saveVideo()" class="btn btn-primary mt-3">Save</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>


import {BAlert, VBTooltip, BTable} from 'bootstrap-vue';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';
import UploadFile from '@/mixins/UploadFile';

export default {
    name: 'contract-videos',
    components: {Spinner, BAlert, BTable},
    mixins: [ProcessError, UploadFile],
    directives: {'b-tooltip': VBTooltip},
    data() {
        return {
            videos: null,
            isEmpty: true,
            page: 1,
            isExclusive: 0,
            loading: false,
            fields: [
                {key: 'video', label: 'Video', sortable: false},
                {key: 'video.video_id', label: 'Video ID', sortable: true},
                {key: 'video.created_at', label: 'Created at', sortable: true},
                {key: 'status_name', label: 'Status', sortable: false},
                {key: 'actions', label: '', sortable: false},
            ],
            errors: {
                show: false,
                message: '',
            },
            statuses: [
                {id: 0, name: 'Active'},
                {id: 1, name: 'Terminated'},
            ],
            selectedVideo: {
                title: null,
                status: null,
                contract_id: null,
                video_id: null,
            },
        }
    },
    methods: {
        async open(contract) {
            this.videos = null;
            this.isEmpty = true;
            await this.fetchVideos(contract);
            this.$bvModal.show('contract-videos-modal');
        },
        fetchVideos(contract) {
            this.loading = true;
            this.resetErrors();
            this.axios.get(
                './admin/contracts/videos.json?contract_id=' + contract.id
            ).then(x => x.data).then((data) => {
                this.videos = data
                this.isEmpty = this.videos.length === 0;
                this.loading = false;
            }).catch((error) => {
                this.processError(error);
            });
        },
        closeModal() {
            this.$bvModal.hide('contract-videos-modal');
        },
        closeVideoEditModal() {
            this.$bvModal.hide('video-edit-modal');
        },
        editVideo(contract) {
            this.selectedVideo.contract_id = contract.contract_id;
            this.selectedVideo.video_id = contract.video_id;
            this.selectedVideo.title = contract.video.title;
            this.selectedVideo.status = this.statuses.find(s => s.id === contract.status);
            console.log(this.selectedVideo);
            console.log(this.statuses);
            this.$bvModal.show('video-edit-modal');
        },
        saveVideo() {
            this.success = false;
            this.loading = true;
            this.$confirm.require(
                {
                    message: `Are you sure want to change video status?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.axios.post('./admin/contracts/edit-video.json', {
                            contract_id: this.selectedVideo.contract_id,
                            video_id: this.selectedVideo.video_id,
                            status: this.selectedVideo.status ? this.selectedVideo.status.id : null,
                        }).then((response) => {
                            const videoId = response.data.video_id;
                            const index = this.videos.findIndex(x => x.video_id === videoId);
                            if (index !== -1) {
                                this.videos[index].status = response.data.status;
                                this.videos[index].status_name = response.data.status_name;
                            }
                            this.closeVideoEditModal();
                            this.loading = false;
                        }).catch(error => {
                            this.errors.message = error.response.data.message
                            this.errors.show = true;
                        });
                    }
                }
            );
        },
    },

};
</script>

<style lang="scss" scoped>
.img-fluid {
    max-width: 150px;
}
</style>

<template>
    <div v-if="agreement">
        <div class="header bg-white py-7 py-lg-8">
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>

        <div class="container mt--8 pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="card bg-secondary shadow-lg border">
                        <div class="card-header bg-transparent px-lg-5">
                            <div class="text-left mt-2">
                                <h3>Dear Partner,</h3>
                                <p class="mt-3 mb-0">
                                    We invite you to familiarize yourself with and accept the updated licensing agreement, which
                                    will become valid from June 1st, 2023.
                                </p>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <spinner v-model="loading"></spinner>
                            <div class="row">
                                <div class="col-lg-12 col-sm-12">
                                    <p style="font-weight: bold">
                                        SUMMARY OF UPDATED CONTENT PROVIDER LICENSE AGREEMENT (valid from June
                                        1st, 2023)
                                    </p>
                                    <ul style="list-style-type: decimal">
                                        <li>
                                            Paragraphs 4-7 of Annex 1 are updated to the following:
                                            <ol start="4" style="font-style: italic">
                                                <li>
                                                    The Bonus payments for videos having length of 3 min and more will be made in
                                                    accordance with the following metrics:
                                                    <div class="row metrics pt-2 pb-2" style="border-radius: 10px">
                                                        <div class="col-6 text-center pages">
                                                            <strong>Views</strong>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <strong>Bonus (USD)</strong>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>1,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>50</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>5,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>100</span>
                                                        </div>
                                                        <div class="col-6 text-center  pages">
                                                            <span>10,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>300</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>15,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>400</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>20,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>500</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>30,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>700</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>40,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>1,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>50,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>2,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>70,000,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>3,000</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>100,000,000+</span>
                                                        </div>
                                                        <div class="col-6 text-center pages">
                                                            <span>5,000</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    Short videos Bonus payments will be made in accordance with the same metrics
                                                    multiplied with Short video coefficient of 0.5 (i.e. 50 percent).
                                                </li>
                                                <li>
                                                    The remuneration amount for Derivative Content videos (such as Compilation
                                                    videos) shall be divided equally between all artists whose videos have been used to
                                                    produce the Derivative Content video unit, as detailed in Terms and Conditions.
                                                </li>
                                                <li>
                                                    Bonus remuneration will be paid to the Creator by Pandos for the monetization of
                                                    Videos on Pandos Facebook pages according to the actual performance of Videos (3
                                                    sec. video views). The performance (views) of every Video posted by Pandos will be
                                                    counted separately as each Video posted by Pandos is eligible for Bonus
                                                    remuneration. The remuneration shall not be paid for non-monetized Facebook use
                                                    of Content (posted videos that on Facebook discretion are not monetized due to
                                                    content or other reasons).
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Upon the termination of Content Providers License Agreement Pandos will delete all
                                            published exclusively licensed Videos on the Creators request.
                                        </li>
                                        <li>
                                            In regard to the abovementioned changes, Paragraphs of Annex 1, previously numbered
                                            5, 6, and 7, are now renumbered as paragraphs 7, 8, and 9. Paragraphs mentioned in
                                            Paragraph 9, are changed from 1.4 and 1.5 to 4-7.
                                        </li>
                                        <li>
                                            All remaining content of Content Providers License Agreement remains unchanged
                                            unless explicitly mentioned in this document.
                                        </li>
                                    </ul>
                                    <p style="text-decoration: underline">
                                        Please note that <b style="color: red">declining</b> these updates may decrease the likelihood of your videos being
                                        published on our Facebook pages for the remaining term of our partnership
                                    </p>
                                    <div class="row">
                                        <b-alert v-model="error" class="w-100" variant="danger">
                                            Error! Please try again!
                                        </b-alert>
                                        <b-alert v-model="accepted" class="w-100" variant="success">
                                            You have successfully accepted updates to the Content Provider License Agreement.
                                        </b-alert>
                                        <b-alert v-model="declined" class="w-100" variant="success">
                                            You have successfully declined updates to the Content Provider License Agreement.
                                        </b-alert>
                                    </div>
                                    <form role="form" class="row" v-if="!accepted && !declined">
                                        <div class="text-center col-md-6">
                                            <base-button @click="accept" type="primary" class="my-4">
                                                Accept
                                            </base-button>
                                        </div>
                                        <div class="text-center col-md-6">
                                            <base-button @click="decline" type="warning" class="my-4">
                                                Decline
                                            </base-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue';
import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    name: 'confirm-agreement',
    components: {Spinner, BAlert},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            id: null,
            agreement: null,
            userToken: null,
            accepted: false,
            declined: false,
            error: false,
        }
    },
    computed: {
        contractUrl() {
            return this.contract ? this.contract.contract_url : null;
        },
    },
    mounted() {
        this.userToken = this.$route.query.token || '';
        if (!this.userToken) {
            window.location.href = '/';
        }
        this.getUserAgreement();
    },
    methods: {
        resetErrors() {
            Object.assign(this.$data.errors, this.$options.data().errors);
        },
        getUserAgreement() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./user/agreement/get.json?token=' + this.userToken
            ).then(x => x.data).then((agreement) => {
                if (!agreement || agreement.status !== this.$constants.agreementStatus.pending) {
                    window.location.href = '/';
                } else {
                    this.agreement = agreement;
                }
                this.loading = false;
            }).catch(() => {
                window.location.href = '/';
            });
        },
        accept() {
            this.save(this.$constants.agreementStatus.accepted);
        },
        decline() {
            this.$confirm.require(
                {
                    message: `Are you sure you want to Decline updates to the Content Provider License Agreement?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.save(this.$constants.agreementStatus.declined)
                    }
                }
            );
        },
        save(status) {
            this.loading = true;
            this.axios.post('./user/agreement/confirm.json', {
                user_token: this.userToken,
                status: status,
            }).then(x => x.data).then(() => {
                if (status === this.$constants.agreementStatus.accepted) {
                    this.accepted = true;
                } else if (status === this.$constants.agreementStatus.declined) {
                    this.declined = true;
                }
                this.loading = false;
            }).catch(() => {
                this.error = true;
                this.loading = false;
            });
        }
    },
}
</script>
<style lang="scss" scoped>
.custom-index {
    z-index: 0;
}
ul, ol {
    @media (max-width: 767px) {
        padding-inline-start: 15px;
    }
}
.metrics {
    border-radius: 5px;
    margin: 10px auto;
    border: 1px solid lightgray;
}
</style>

<template>
    <stats-card title="Reward"
                type="gradient-yellow"
                :sub-title=rewardSubtitle()
                icon="ni ni-money-coins"
    >
        <template slot="footer">
            <div class="reward mb-2 mr-2">
                <span class="text-success text-xl">
                    ${{ Number(Number(currentMonthReward).toFixed(2)).toLocaleString() }}
                </span>
                <i class="fas fa-info-circle align-top"
                   title="Payments are made within  20th day of the calendar month."
                   v-b-tooltip.hover
                ></i>
                <span class="ml-2 mr-2 text-nowrap"
                      :class="{
                            'text-success': rewardIncrease >= 0,
                            'text-danger': rewardIncrease < 0
                        }"
                >
                    <i :class="{
                        'fas fa-arrow-up': rewardIncrease >= 0,
                        'fas fa-arrow-down': rewardIncrease < 0
                    }"
                    ></i> {{ Number(Math.abs(rewardIncrease).toFixed(2)).toLocaleString() }}%
                </span>
                <span class="text-nowrap">Since last {{ $isExclusiveDashboard ? 'quarter' : 'month' }}</span>
            </div>
            <p class="text-nowrap text-sm" v-if="monthlyFee > 0">
                Monthly fee: <span class="text-success">${{ Number(monthlyFee).toFixed(2) }}</span>
            </p>
            <p class="text-nowrap text-sm">
                Minimum payout: <span class="text-success">$50.00</span>
            </p>
        </template>
    </stats-card>
</template>

<script>

import {VBTooltip} from 'bootstrap-vue';

export default {
    name: 'current-month-rewards',
    directives: {'b-tooltip': VBTooltip},
    data() {
        return {
            currentMonthReward: 0,
            lastMonthReward: 0,
            rewardIncrease: 0,
            monthlyFee: 0,
        };
    },
    mounted() {
        this.fetchCurrentMonthReward();
    },
    methods: {
        fetchCurrentMonthReward() {
            this.axios.get('./user/rewards/summary.json').then(x => x.data).then((rewards) => {
                this.currentMonthReward = rewards.current_month;
                this.lastMonthReward = rewards.last_month;
                this.rewardIncrease = rewards.increase;
                this.monthlyFee = rewards.monthly_fee;
            }).catch(error => {
                this.console.log(error);
            });
        },
        rewardSubtitle() {
            return this.$isExclusiveDashboard ? "Current Yearly Quarter" : "Current Month";
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
